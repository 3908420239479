import React, { useEffect, useState } from "react";
import { Tabs, Form, Row, Modal, Col, Input, Button, Space } from "antd";
import "./Styles/UserSetting.scss";
import Footer from "./Footer";
import Cookies from "js-cookie";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import PaymentModal from "./PaymentModal.js";
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);
const TabPane = Tabs.TabPane;
const tabItemClick = (key) => {
  console.log("tab click", key);
};

const UserSetting = () => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onFinish = async (value) => {
    const password = {
      password: value,
    };
    const response = await axios.post(`${baseUrl}change_password`, password, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.password) {
      const stripe = await stripePromise;
      const { error } = await stripe
        .redirectToCheckout({
          sessionId: response.data.password,
        })
        .then(function (result) {
          console.log(result.error.message);
        });
    }
  };

  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  console.log(windowSize, "windowSize");
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <Row justify="" className="pd_20 mt_2 font-28 font_weight700">
          {" "}
          User settings
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={20}>
            <Tabs
              defaultActiveKey="1"
              tabPosition="left"
              onChange={tabItemClick}
            >
              <TabPane tab="Change Password" key="1">
                <Row style={{ justifyContent: "center" }}>
                  <Col span={24}>
                    <Form
                      form={form}
                      className="userSettingFormMain"
                      style={{}}
                      onFinish={onFinish}
                      htmlType="submit"
                      layout="vertical"
                    >
                      <Form.Item
                        label="Old Password"
                        name="Old Password"
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please input your Old Password!",
                          },
                          {
                            min: 8,
                            message: "Password must have a minimum length of 8",
                          },
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Form.Item
                        name=" New Password"
                        label=" New Password"
                        dependencies={["Old Password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please input your New Password!",
                          },
                          {
                            min: 8,
                            message: "Password must have a minimum length of 8",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("Old Password") === value
                              ) {
                                return Promise.reject(
                                  new Error(
                                    "You used this password recently. Please choose a different one."
                                  )
                                );
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                      <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={[" New Password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your New Password!",
                          },
                          {
                            min: 8,
                            message: "Password must have a minimum length of 8",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue(" New Password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Please enter a same password")
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>

                      <Col style={{ textAlign: "right" }}>
                        <Form.Item>
                          <Space>
                            <Button type="primary" htmlType="submit">
                              Save
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                form.resetFields();
                              }}
                            >
                              Cancel
                            </Button>
                          </Space>
                        </Form.Item>
                      </Col>
                    </Form>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tab="Manage Payment" key="2">
                <Row
                  className="pd_20 ManagepaymentRow"
                  style={{
                    minHeight: "355px",
                  }}
                >
                  <Col span={24}>
                    <Row className="font-26 font_weight700">
                      Membership status
                    </Row>
                    <Row>You do not have a current membership.</Row>
                    <Button type="primary" className="mt_2" onClick={showModal}>
                      Buy Now
                    </Button>
                    <Modal
                      centered
                      width={1000}
                      title="Basic Modal"
                      open={isModalOpen}
                      onOk={handleOk}
                      onCancel={handleCancel}
                    >
                      <PaymentModal />
                    </Modal>
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </div>
      {/* <Row  style={{ position: "fixed", width: "100%", bottom: "0" }}> */}
      <Col span={24}>
        <Footer />
      </Col>
      {/* </Row> */}
    </>
  );
};

export default UserSetting;
