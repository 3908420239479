import { Grid } from "@material-ui/core";
import { Card, Col, Input, Row, Spin, Tag } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  addCommas,
  authLoginChecker,
  fetchSearchParams,
  getUrlVars,
  updateSearchParams,
  updateSearchParams1,
  updateSearchParams2,
} from "../helper/commonFunction";
import CustomTable from "./CustomTable";
import Filter from "./Filter";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import "./Styles/Dashboard.scss";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Investors = (props) => {
  const location = useLocation();

  const [apiData, setApiData] = useState("");
  const [listData, setListData] = useState("");
  const [pageOption, setPageOption] = useState({
    // size: 100,
    // page: 1,
    reporter: "",
    ticker: "",
    issuer: "",
    transaction_code: "",
  });

  const log = (type, data) => {
    setTableLoading(true);
    if (type === "officer") {
      const officerData = decodeURIComponent(pageOption.officer)
        .split(",")
        .map((ind) => ind);
      pageOption.officer = encodeURIComponent(
        officerData.filter((datas) => datas !== data)
      );
      setPageOption(pageOption);
      updateSearchParams2(
        pageOption,
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "transaction_code") {
      const updateData = _.omit(pageOption, "transaction_code");
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "transaction_code",
        "",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "ticker") {
      const updateData = _.omit(pageOption, "ticker");
      setPageOption(updateData);
      updateSearchParams1(
        pageOption,
        "ticker",
        "",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "issuer") {
      const updateData = decodeURIComponent(pageOption.issuer)
        .split(",")
        .map((ind) => ind);
      pageOption.issuer = updateData.filter((datas) => datas !== data);
      updateSearchParams(pageOption, history, "/investors/page_type=investors");
    } else if (type === "filing_dt_min") {
      const updateData = _.omit(pageOption, ["filing_dt_min", "filing_dt_max"]);

      updateSearchParams1(
        updateData,
        "filing_dt_min",
        "filing_dt_max",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "reported_dt_min") {
      const updateData = _.omit(pageOption, [
        "reported_dt_min",
        "reported_dt_max",
      ]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "reported_dt_min",
        "reported_dt_max",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "traded_dt_min") {
      const updateData = _.omit(pageOption, ["traded_dt_min", "traded_dt_max"]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "traded_dt_min",
        "traded_dt_max",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "price_min") {
      const updateData = _.omit(pageOption, ["price_min", "price_max"]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "price_min",
        "price_max",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "qty_min") {
      const updateData = _.omit(pageOption, ["qty_min", "qty_max"]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "qty_min",
        "qty_max",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "total_min") {
      const updateData = _.omit(pageOption, ["total_min", "total_max"]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "total_min",
        "total_max",
        history,
        "/investors/page_type=investors"
      );
    } else if (type === "reporter") {
      const updateData = _.omit(pageOption, "reporter");
      setPageOption(updateData);
      updateSearchParams1(
        pageOption,
        "reporter",
        "",
        history,
        "/investors/page_type=investors"
      );
    }
  };

  const { Search } = Input;
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState({
    qty_min: 5,
    qty_max: 5000000,
    price_min: 1,
    price_max: 10000,
    total_min: 1,
    total_max: 1000000,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [isTickerSearch, setIsTickerSearch] = React.useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [filteredIndustry, setFilteredIndustry] = useState([]);

  const getTitleData = async (request) => {
    let queryParamsForAPi;
    if (request.issuer && request.reporter) {
      queryParamsForAPi = `?issuer=${request.issuer}&reporter=${request.reporter}&ticker=${request.ticker}`;
    } else if (request.issuer) {
      queryParamsForAPi = `?issuer=${request.issuer}`;
    } else if (request.reporter) {
      queryParamsForAPi = `?reporter=${request.reporter}`;
    } else if (request.ticker) {
      queryParamsForAPi = `?ticker=${request.ticker}`;
    }
    await axios
      .get(`${baseUrl}investors${queryParamsForAPi ? queryParamsForAPi : ""}`)
      .then((result) => {
        let data = result.data.data;
        setListData(data);
        let filterData = [];
        let array = [];
        if (request && request.reporter) {
          array = request.reporter.split(",");
          array = array.map((x) => Number(x));
        }
        data.map((list) => {
          array.map((listS) =>
            list.cik === listS ? filterData.push(list) : ""
          );
        });
        setFilteredIndustry(filterData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = () => {
    const request = fetchSearchParams(pageOption);
    setTableLoading(true);
    if (!isLoading) {
      fetch(`${baseUrl}data${window.location.search}`, {
        method: "GET",
      })
        .then((resp) => resp.json())
        .then((data) => {
          setTotalRecord(data.count);
          if (data && data.data) {
            if (data && data.count === 0) {
              // openNotification();
            }
            const uniqueData = removeDuplicatesById(data?.data);
            setApiData(uniqueData);
            const allVars = getUrlVars();
            let array = [];
            if (request && request.reporter) {
              array = request.reporter.split(",");
              array = array.map((x) => Number(x));
            }
            setPageOption({
              // ...pageOption,
              ...allVars,
              // size: request.size,
              // page: request.page,
              reporter: array,
            });
            setCheckedList(array);
          }
        })
        .catch((err) => {
          setApiData("err");
        })
        .finally(() => {
          // setIsLoading(false);
          // setTableLoading(false);
          getTitleData(request);
        });
    }
  };

  const removeDuplicatesById = (arr) => {
    const uniqueObjects = [];
    const seenIds = new Set();
    arr.forEach((item) => {
      if (!seenIds.has(item.id)) {
        uniqueObjects.push(item);
        seenIds.add(item.id);
      }
    });
    return uniqueObjects;
  };

  const [isResetFilter, setIsResetFilter] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  const handleChange = async (reporter) => {
    // setTableLoading(true);
    setCheckedList(reporter);
    setIndeterminate(!!reporter.length && reporter.length < listData.length);
    setCheckAll(reporter.length === listData.length);
    const request = {
      ...pageOption,
      reporter,
    };
  };
  const user_logged_in = !authLoginChecker();
  return (
    <>
      <Row className="dashboardMainTable">
        {tableLoading ? (
          <Row
            justify="center"
            align="middle"
            className="dashboardmaindiv individual_investors_page"
          >
            <Spin
              size="large"
              tip="Loading..."
              // spinning={isLoading}
            ></Spin>
          </Row>
        ) : (
          <Row
            className="dashboardmaindiv dashboardInvestorsTableMain"
            justify="center"
          >
            {apiData ? (
              apiData === "err" ? (
                <div>something went wrong, comeback later</div>
              ) : (
                <>
                  {/* <DataGrid
                    rows={apiData}
                    rowHeight={30}
                    autoHeight={true}
                    columns={columns}
                    hideFooterPagination={true}
                    hideFooterRowCount={true}
                    hideFooter={true}
                    hideFooterSelectedRowCount={true}
                  /> */}
                  <Col
                    xl={3}
                    md={6}
                    xs={24}
                    className="individual_investors_Sidebar"
                  >
                    <Row className="industry_checkbox_grp_individual">
                      <Card
                        className="individual_investors_sidebar_card"
                        bodyStyle={{ padding: "12px" }}
                      >
                        <Row className="individual_investors_sidebar_title">
                          Choose an Investor
                        </Row>
                        <Sidebar
                          tableLoading={tableLoading}
                          setSearch={setSearch}
                          handleChange={handleChange}
                          setApiData={setApiData}
                          getUrlVars={getUrlVars}
                          setTotalRecord={setTotalRecord}
                          fetchSearchParams={fetchSearchParams}
                          setPageOption={setPageOption}
                          pageOption={pageOption}
                          getTitleData={getTitleData}
                          setTableLoading={setTableLoading}
                          isLoading={isLoading}
                          listData={listData}
                          search={search}
                          setCheckedList={setCheckedList}
                          checkedList={checkedList}
                        />
                      </Card>
                    </Row>
                  </Col>
                  <Col
                    md={18}
                    xl={21}
                    xs={24}
                    className="deshboardTableSecondPart"
                  >
                    <Row>
                      <Row
                        justify="center"
                        className="scrollable-container deshboardTable__firstRow individual_investors_table"
                      >
                        <Col span={24}>
                          <Grid item xs={12}>
                            <div className="individual_investors_table_part">
                              {tableLoading ? (
                                <Row
                                  justify="center"
                                  align="middle"
                                  className="dashboardmaindiv individual_investors_table_main"
                                >
                                  <Spin size="large" tip="Loading..."></Spin>
                                </Row>
                              ) : (
                                <>
                                  <Filter
                                    setPageOption={setPageOption}
                                    setFilteredData={setFilteredData}
                                    filteredData={filteredData}
                                    isResetFilter={isResetFilter}
                                    filterLoading={filterLoading}
                                    filterData={setApiData}
                                    apiData={apiData}
                                    checkedList={checkedList}
                                    setTableLoading={setTableLoading}
                                    fetchSearchParams={fetchSearchParams}
                                    pageOption={pageOption}
                                    keyName="reporter"
                                  />
                                  <Row className="dashboardSecondTablePart">
                                    <Row className="individual_investors_table_second_part">
                                      {pageOption.transaction_code ? (
                                        <span>
                                          {
                                            // decodeURIComponent(
                                            //   pageOption.transaction_code
                                            // )
                                            //   .split(",")
                                            //   .map((ind) => {
                                            //     return (
                                            <Tag
                                              className="close_tag"
                                              style={
                                                user_logged_in && {
                                                  opacity: 0.5,
                                                  cursor: "no-drop",
                                                }
                                              }
                                              closable={!user_logged_in}
                                              onClose={() =>
                                                log(
                                                  "transaction_code",
                                                  pageOption.transaction_code
                                                )
                                              }
                                            >
                                              {pageOption.transaction_code}{" "}
                                            </Tag>
                                            //   );
                                            // })
                                          }
                                        </span>
                                      ) : (
                                        ""
                                      )}

                                      {pageOption.qty_min ||
                                      pageOption.qty_max ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() =>
                                            log("qty_min", pageOption.qty_min)
                                          }
                                        >
                                          Quantity -{" "}
                                          {addCommas(pageOption.qty_min || 0)}
                                          ...
                                          {addCommas(pageOption.qty_max)}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}

                                      {pageOption.traded_dt_min ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() =>
                                            log(
                                              "traded_dt_min",
                                              pageOption.traded_dt_min
                                            )
                                          }
                                        >
                                          Traded date -{" "}
                                          {moment(
                                            pageOption.traded_dt_min
                                          ).format("MM-DD-YYYY")}
                                          {moment(
                                            pageOption.traded_dt_max
                                          ).format("MM-DD-YYYY")}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.reported_dt_min ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() =>
                                            log(
                                              "reported_dt_min",
                                              pageOption.reported_dt_min
                                            )
                                          }
                                        >
                                          Reported date -{" "}
                                          {moment(
                                            pageOption.reported_dt_min
                                          ).format("MM-DD-YYYY")}
                                          ...
                                          {moment(
                                            pageOption.reported_dt_max
                                          ).format("MM-DD-YYYY")}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.filing_dt_min ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() =>
                                            log(
                                              "filing_dt_min",
                                              pageOption.filing_dt_min
                                            )
                                          }
                                        >
                                          Filling date -{" "}
                                          {moment(
                                            pageOption.filing_dt_min
                                          ).format("MM-DD-YYYY")}
                                          ...
                                          {moment(
                                            pageOption.filing_dt_max
                                          ).format("MM-DD-YYYY")}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.price_min ||
                                      pageOption.price_max ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() =>
                                            log(
                                              "price_min",
                                              pageOption.price_min
                                            )
                                          }
                                        >
                                          {" "}
                                          Price -{" "}
                                          {addCommas(pageOption.price_min || 0)}
                                          ...
                                          {addCommas(pageOption.price_max)}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.total_min ||
                                      pageOption.total_max ? (
                                        <Tag
                                          className="close_tag"
                                          style={
                                            user_logged_in && {
                                              opacity: 0.5,
                                              cursor: "no-drop",
                                            }
                                          }
                                          closable={!user_logged_in}
                                          onClose={() =>
                                            log(
                                              "total_min",
                                              pageOption.total_min
                                            )
                                          }
                                        >
                                          Total -{" "}
                                          {addCommas(pageOption.total_min || 0)}
                                          ...
                                          {addCommas(pageOption.total_max)}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.officer ? (
                                        <span>
                                          {decodeURIComponent(
                                            pageOption.officer
                                          )
                                            .split(",")
                                            .map((ind) => {
                                              // debugger
                                              return (
                                                <Tag
                                                  className="close_tag"
                                                  closable
                                                  onClose={() =>
                                                    log("officer", ind)
                                                  }
                                                >
                                                  {ind}{" "}
                                                </Tag>
                                              );
                                            })}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.issuer ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() => (
                                            log("issuer", pageOption.issuer),
                                            localStorage.removeItem("issuer")
                                          )}
                                        >
                                          Issuer -{" "}
                                          {localStorage.getItem("issuer")}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}

                                      {pageOption.ticker ? (
                                        <Tag
                                          className="close_tag"
                                          closable
                                          onClose={() =>
                                            log("ticker", pageOption.ticker)
                                          }
                                        >
                                          Ticker - {pageOption?.ticker}
                                        </Tag>
                                      ) : (
                                        ""
                                      )}

                                      {pageOption.issuer ? (
                                        apiData[0]?.issuer_cik ===
                                        pageOption.issuer ? (
                                          <Tag
                                            className="close_tag"
                                            closable
                                            onClose={() =>
                                              log("issuer", pageOption.issuer)
                                            }
                                          >
                                            Issuer - {apiData[0]?.issuer_name}
                                          </Tag>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                      {pageOption.reporter ? (
                                        filteredIndustry[0]?.cik ===
                                        pageOption.reporter ? (
                                          <Tag
                                            className="close_tag"
                                            closable
                                            onClose={() => (
                                              log(
                                                "reporter",
                                                pageOption.reporter
                                              ),
                                              setSearch(" ")
                                            )}
                                          >
                                            Reporter -{" "}
                                            {filteredIndustry[0]?.name}
                                          </Tag>
                                        ) : (
                                          ""
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </Row>
                                    <Row className="custom_table_main">
                                      <CustomTable
                                        apiData={apiData}
                                        tableType="investors"
                                        totalRecord={totalRecord}
                                      />
                                    </Row>
                                  </Row>
                                </>
                              )}
                              {/* <Row
                                justify="center"
                                align="middle"
                                style={{
                                  marginTop: "15px",
                                }}
                              >
                                <Col xs={2}></Col>
                                <Col
                                  xs={20}
                                  style={{
                                    marginBottom: "20px",
                                    marginTop: "63px",
                                  }}
                                >
                                  <Pagination
                                    style={{ float: "right" }}
                                    defaultCurrent={pageOption.page}
                                    total={totalRecord}
                                    size="small"
                                    pageSizeOptions={[100, 150, 200, 250, 300]}
                                    onChange={onPageChange}
                                    pageSize={pageOption.size}
                                  />
                                </Col>
                                <Col xs={2} />
                              </Row>
                             */}
                            </div>
                          </Grid>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                </>
              )
            ) : (
              <Row
                justify="center"
                align="middle"
                className="individual_investors_table_main"
              >
                <Spin size="large" tip="Loading..."></Spin>
              </Row>
            )}
          </Row>
        )}
      </Row>
      <Footer tableLoading={tableLoading} />
    </>
  );
};

export default Investors;
