import React, { useRef } from "react";
import "./Styles/Dashboard.scss";
import { Row, Col } from "antd";
import Footer from "./Footer";
import { NavLink } from "react-router-dom";
import HomeMain from "./HomeMain";
import HomePageSEO from "./HomePageSEO";
import HomePageInvestor from "./HomePageInvestor";
import Dashboard from "./Dashboard";
import "./Styles/Dashboard.scss";

const Home = () => {
  const scrollRef = useRef();
  return (
    <>
      <HomeMain scrollRef={scrollRef} />
      <HomePageSEO scrollRef={scrollRef} />
      <HomePageInvestor />
      <Row className="homepage_footer" />
      {/* <Dashboard /> */}
      <Footer />
    </>
  );
};
export default Home;
