import { Button, Col, Row, Typography } from "antd";
import React from "react";
import "../Components/Styles/HomeMain.scss";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const HomeMain = ({ scrollRef }) => {
  const history = useHistory();
  const handleScroll = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Row style={{ backgroundColor: "#F5F5F5" }} align="center">
      <Row className="backGroundimage-main">
        <Col offset={4} xs={16}>
          <Typography className="homePageTitle">
            100ktrading provides real time insider data for SEC form4 filings
            (limited to non-derivative filings).
            <br />
            <Button
              className="homeMainReportButton"
              onClick={() =>
                history.push("/filter?total_min=5000000&transaction_code=Purchases")
              }
            >
              Click Here for Reports!!
            </Button>
          </Typography>
        </Col>
      </Row>
      <Row className="arrowiconHomeDiv" onClick={() => handleScroll()}>
        <DownOutlined className="arrowIcon" />
      </Row>
    </Row>
  );
};

export default HomeMain;
