import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import HomeSEO from "../assets/HomeSecond.png";
import "../Components/Styles/HomeMain.scss";

const HomePageSEO = ({scrollRef}) => {
  return (
    <>
      <Row className="seoMainPart" ref={scrollRef}>
        <Col lg={{span:5, offset:4}} xs={{ span: 14, offset: 5 }}>
          <img  width="100%"
         className="seoMainImage" alt="seoImage" src={HomeSEO} />
        </Col>
        <Col className="seoTitlepartContent" lg={{offset:1, span: 10}}  xs={{ span: 20, offset: 2 }} >
          <Typography className="seoMainTitle">What is SEC Form 4?</Typography>
          <Typography className="seoTitleAnswer">
            Form 4: Statement of Changes in Beneficial Ownership is a two-page
            document in which insiders must list any recent Purchases or sales
            of scompany stock they have made, as well as the exercise of any
            company options. The form requires insiders to list the details of
            any such transactions, their holding in the company after the
            transactions, and their relationship to the company.
          </Typography>
          <Divider />
          <Typography className="seoMainTitle">
            Who is required to file Form 4?
          </Typography>
          <Typography className="seoTitleAnswer">
            Insiders consist of officers and directors of a company, as well as
            any shareholders that own 10% or more of a company's outstanding
            stock..
          </Typography>
          <Divider />
          <Typography className="seoMainTitle">
            When does a Form 4 need to be filed?
          </Typography>
          <Typography className="seoTitleAnswer">
            Form 4 is required to be filed before the end of the second business
            day following the day on which a transaction resulting in a change
            in beneficial ownership has been executed.
          </Typography>
        </Col>
      </Row>
    </>
  );
};

export default HomePageSEO;
