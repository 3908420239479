import {
  FacebookOutlined,
  GoogleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import FacebookLogin, {
  FacebookLoginClient,
} from "@greatsumini/react-facebook-login";
// import { GoogleLogin, googleLogout, useGoogleLogin } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { Button, Card, Col, Form, Input, Row } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { openNotification } from "./Notification";
import { axios } from "axios";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { jwtDecode } from "jwt-decode";

const baseUrl = process.env.REACT_APP_BASE_URL;
const Login = (props) => {
  const {
    openLoginModal,
    setOpenLoginModal,
    setOpenRegisterModal,
    openRegisterModal,
    forgetPasswordModal,
    setForgetPasswordModal,
  } = props;
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const location = useLocation();
  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  // const handleRegister = () => {
  //   // setOpenRegisterModal(true);
  //   setOpenLoginModal(false);
  // };
  const handleForget = () => {
    setForgetPasswordModal(true);
    // setOpenLoginModal(false);
  };
  const [loader, setLoader] = useState(false);
  // const [openLoginModal, setOpenLoginModal] = useState(false);
  const [signinError, setSigninError] = useState("");
  const history = useHistory();
  const onFinish = (values) => {
    Cookies.set("user_logged_in", "Y");
    fetch(`${baseUrl}register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://premsite.com/",
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((data) => {
        checkSubscription(values?.email);
      })
      .catch((err) => {
        openNotification("Something went wrong, comeback later", "error");
        Cookies.set("user_logged_in", "N");
        history.push("/login");
        setLoader(false);
      });
    // .finally(() => {
    //   setLoadingButton(false);
    //   // FacebookLoginClient.logout()
    //   // setOpenLoginModal(false);
    // });
  };
  const checkSubscription = async (value) => {
    console.log(value, "value");
    const payload = {
      email: value,
    };
    fetch(`${baseUrl}check_customer_subscriptions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://premsite.com/",
      },
      body: JSON.stringify(payload),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data?.code === 200) {
          setLoadingButton(false);
          history.push("/filter?total_min=5000000&transaction_code=Purchases");
          Cookies.set("user_logged_in", "Y");
        } else {
          history.push("/product-subscription");
          Cookies.set("user_logged_in", "Y");
        }
      })
      .catch((err) => {
        openNotification("Something went wrong, comeback later", "error");
        Cookies.set("user_logged_in", "N");
        history.push("/login");
        setLoader(false);
      });
  };
  function CustomComponent({ onClick, onLogoutClick }) {
    return <FacebookLoginButton onClick={() => onClick()} />;
  }

  const handleLoginFacebook = (response) => {
    const data = {
      first_name: response?.name?.split(" ")[0],
      last_name: response?.name?.split(" ")?.at(-1),
      password: "None",
      userid: response?.email,
      email: response?.email,
      phone: response?.phone || "",
      login_source: "fb",
    };
    onFinish(data);
    // Cookies.set("user_logged_in", 'Y');
    // history.push("/");
  };

  const googleLogin = async (params) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${params.access_token}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${params.access_token}`,
            Accept: "application/json",
          },
        }
      );
      if (response.ok) {
        const userData = await response.json();
        const data = {
          first_name: userData?.name,
          last_name: userData?.family_name,
          password: "None",
          userid: userData?.email,
          email: userData?.email,
          phone: userData?.phone || "",
          login_source: "gmail",
        };
        // Cookies.set("user_logged_in", 'Y');
        // history.push("/");
        onFinish(data);
      } else {
        console.error("Failed to fetch user data:", response.statusText);
        Cookies.set("user_logged_in", "N");
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      Cookies.set("user_logged_in", "N");
    }
  };

  // const handleGoogleLogin = () => {
  //   setLoader(true);
  //   // Replace the clientId with your actual Google OAuth client ID
  //   const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  //   const redirectUri = 'http://localhost:3000/';
  //   const scope = "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/youtube.readonly";
  //   const authUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;
  //   // Redirect to Google OAuth URL
  //   // window.location.href = authUrl;
  //   console.log(authUrl, 'authUrlauthUrl')
  // };
  const signIn = () => {
    const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const params = {
      client_id: clientId,
      redirect_uri: `${process.env.REACT_APP_LIVE_URL}googleLoginSuccess`,
      response_type: "token",
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/youtube.readonly",
      include_granted_scopes: true,
      state: "pass-through-value",
    };
    const queryString = Object.keys(params)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
      )
      .join("&");
    window.location.href = `${oauth2Endpoint}?${queryString}`;
  };
  useEffect(() => {
    if (location.pathname === "/googleLoginSuccess") {
      setLoadingButton(true);
      let params = {};
      let regex = /([^&=]+)=([^&]*)/g,
        m;
      while ((m = regex.exec(window.location.href))) {
        params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
      }
      Cookies.set("google_login", params.access_token);
      googleLogin(params);
    }
  }, []);

  return (
    <Row align="middle" justify="center">
      <Col
        xs={22}
        sm={16}
        style={{
          backgroundColor: "#F5F5F5",
          //  height: "90vh"
        }}
      >
        <Card>
          <Row>
            <Col span={24}>
              <Form form={form} layout="vertical">
                <Row>
                  <h1 style={{ fontWeight: "bold" }}>Login Now</h1>
                </Row>
                {/* <Row>
                  <Form.Item
                    style={{ width: "100%" }}
                    name="userid"
                    rules={[
                      {
                        required: true,
                        message: "Please input your userid!",
                      },
                    ]}
                    label={<span className="t_gray">User Id</span>}
                  >
                    <Input placeholder="Your userid" />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item
                    style={{ width: "100%" }}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    label={<span className="t_gray">Password</span>}
                  >
                    <Input.Password placeholder="Your password" />
                  </Form.Item>
                </Row>
                <Row justify="end">
                  <p
                    style={{ fontWeight: "500" }}
                    className="cursor_pointer "
                    onClick={handleForget}
                  >
                  </p>
                </Row>
                <small className="signinError">{signinError}</small> */}
                {/* <Row>
                  <Form.Item style={{ width: "100%" }} shouldUpdate>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          height: "50px",
                        }}
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        LOGIN NOW
                      </Button>
                    )}
                  </Form.Item>
                </Row> */}
                {/* <button onClick={handleLoginClick}>Login with Google</button> */}
                <Row justify="center" align="middle">
                  <Col>
                    <FacebookLogin
                      appId="266512613168261"
                      cssClass="btnFacebook"
                      // onSuccess={(response) => {
                      //   console.log('Login Success!', response);
                      // }}
                      // onFail={(error) => {
                      //   console.log('Login Failed!', error);
                      // }}
                      onProfileSuccess={(response) => {
                        console.log("Get Profile Success!", response);
                        handleLoginFacebook(response);
                      }}
                      render={({ onClick, logout }) => (
                        <CustomComponent
                          onClick={onClick}
                          onLogoutClick={logout}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    {!loadingButton ? (
                      <GoogleLoginButton
                        onClick={signIn}
                        disabled={loadingButton}
                      />
                    ) : (
                      <Button
                        disabled
                        size="large"
                        style={{ height: "50px", width: "210px" }}
                      >
                        <LoadingOutlined /> Log in with google
                      </Button>
                    )}
                  </Col>
                  {/* <GoogleLogin
                    size="large"
                    logo_alignment="left"
                    ux_mode='redirect'
                    select_account={true}
                    redirect_uri="http://localhost:3000/"
                    onSuccess={(credentialResponse) => {
                      history.push("/");
                      console.log(credentialResponse, 'credentialResponse')
                      let decoded = jwtDecode(credentialResponse?.credential);
                      const data = {
                        first_name: decoded?.given_name,
                        last_name: decoded?.family_name,
                        password: "None",
                        userid: decoded?.email,
                        email: decoded?.email,
                        phone: decoded?.phone || "",
                      };
                      Cookies.set("user_logged_in", "Y");
                      history.push("/");
                      // onFinish(data);
                    }
                    }
                    onError={() => {
                      console.log("Login Failed");
                    }}
                  /> */}
                  {/* <Button onClick={handleGoogleLogin} loading={loader}>
                    Login with Google
                  </Button> */}
                </Row>
                {/* <Row justify="center" style={{ marginTop: '20px' }}>
                  <p
                    style={{ fontWeight: "500", color: "gray" }}
                  // onClick={() => history.push("/ForgotPasswordWeb")}
                  >
                    Are you new user?
                    <NavLink to="/register" className="cursor_pointer pl_5">
                      Register Now
                    </NavLink>
                  </p>
                </Row> */}
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
