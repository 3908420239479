import React, { useEffect, useState } from "react";
import { Row, Col, Card, Input, Form, Button } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { openNotification } from "./Notification";
import Cookies from "js-cookie";
const baseUrl = process.env.REACT_APP_BASE_URL;
const Register = (props) => {
  // const { openLoginModal, setOpenLoginModal, setOpenRegisterModal, openRegisterModal } =
  //   props;

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);

  const [login, setLogin] = useState(true);
  const [register, setRegister] = useState(false);
  const [loader, setLoader] = useState(false);
  const [signupError, setSignupError] = useState("");
  const history = useHistory();
  // const handleLogin = () => {
  //   setOpenRegisterModal(false);
  //   setOpenLoginModal(true);
  // };
  const onFinish = (values) => {
    fetch(`${baseUrl}register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://premsite.com/",
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "success") {
          sessionStorage.setItem("islogged", true);
          // history.push("/dashboard");
          // console.log(data, "datattt");
          Cookies.set("userID", data && data.message && data.message.userid);
          setLoader(false);
          setLogin(true);
          setRegister(false);
          setSignupError("");
          // setOpenRegisterModal(false);
          history.push("/login");
        } else {
          openNotification(
            "Email already registered with us, try another one",
            "error"
          );
          setLoader(false);
        }
      })
      .catch((err) => {
        setLoader(false);
        openNotification("Something went wrong, comeback later", "error");
      });
  };
  return (
    <Row align="middle" justify="center">
      <Col
        xs={22}
        sm={16}
        style={{
          backgroundColor: "#F5F5F5",
          height: "auto",
          // paddingTop: "3em",
          // paddingBottom: "3em",
        }}
      >
        <Card>
          <Row style={{ justifyContent: "center" }} className="mt_3">
            <Col xs={22} sm={20} md={18} lg={18} xl={20}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row>
                  <h1 style={{ fontWeight: "bold", padding: "0px 15px" }}>
                    Signup Now
                  </h1>
                </Row>
                <Row>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      style={{ width: "100%", padding: "0px 10px" }}
                      name="first_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name!",
                        },
                      ]}
                      label={<span className="t_gray">First Name</span>}
                    >
                      <Input placeholder="Your first name" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      style={{ width: "100%", padding: "0px 10px" }}
                      name="last_name"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name!",
                        },
                      ]}
                      label={<span className="t_gray">Last Name</span>}
                    >
                      <Input placeholder="Your last name" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      style={{ width: "100%", padding: "0px 10px" }}
                      name="userid"
                      rules={[
                        {
                          required: true,
                          message: "Please input your userid!",
                        },
                      ]}
                      label={<span className="t_gray">User Id</span>}
                    >
                      <Input placeholder="Your userid" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      style={{ width: "100%", padding: "0px 10px" }}
                      name="phone"
                      rules={[
                        { len: 10, message: "Enter valid number" },
                        {
                          required: true,
                          message: "Please input your phone!",
                        },
                      ]}
                      label={<span className="t_gray">Phone</span>}
                    >
                      <Input type="number" placeholder="Your phone" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      style={{ width: "100%", padding: "0px 10px" }}
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your email!",
                        },
                      ]}
                      label={<span className="t_gray">Email</span>}
                    >
                      <Input placeholder="Your email" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      style={{ width: "100%", padding: "0px 10px" }}
                      name="password"
                      rules={[
                        {
                          pattern: new RegExp(
                            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
                          ),
                          message:
                            "Weak password (include A-Z, a-z, 0-9, @$%#)",
                        },
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      label={<span className="t_gray">Password</span>}
                    >
                      <Input.Password placeholder="Your password" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Form.Item
                      name="confirm"
                      style={{ width: "100%", padding: "0px 10px" }}
                      // label="Re-Type Password"
                      dependencies={["password"]}
                      hasFeedback
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The two passwords that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                      label={<span className="t_gray">Re-Type Password</span>}
                    >
                      <Input.Password placeholder="Re-Type Password" />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Form.Item
                    style={{ width: "100%", padding: "0px 9px" }}
                    shouldUpdate
                  >
                    {/* <Button type="primary" htmlType="submit"> */}

                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          height: "50px",
                        }}
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        REGISTER NOW
                      </Button>
                    )}
                  </Form.Item>
                </Row>

                <Row justify="center">
                  <p
                    style={{ fontWeight: "500", color: "gray" }}
                    // onClick={() => history.push("/ForgotPasswordWeb")}
                  >
                    Are you already User?
                    <NavLink to="/login" className="cursor_pointer pl_5">
                      Login Now
                    </NavLink>
                  </p>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Register;
