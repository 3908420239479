import { Paper, Popper, Typography } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { Input, Row } from "antd";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getUrlVars } from "../helper/commonFunction";
import { PageContext } from "./Routing";

const CustomTable = ({ apiData, tableType, totalRecord }) => {
  const history = useHistory();

  // const { page, setPage } = useContext(PageContext);
  const [isTickerSearch, setIsTickerSearch] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [inputValue, setInputValue] = useState(String(pageSize));
  const location = useLocation();

  function apiUrlFilterWith() {
    const searchParams = location?.search?.split("?")[1]?.split("&");

    if (searchParams) {
      if (searchParams?.length >= 3 && searchParams[2] !== undefined) {
        return `&${searchParams[0]}&${searchParams[1]}&${searchParams[2]}`;
      } else if (searchParams.length >= 2 && searchParams[1] !== undefined) {
        return `&${searchParams[0]}&${searchParams[1]}`;
      } else if (searchParams.length >= 1 && searchParams[0] !== undefined) {
        return `&${searchParams[0]}`;
      }
    }
    if (searchParams === undefined) {
      return "";
    }
  }
  const modifiedApiUrlFilterWith = apiUrlFilterWith();

  function removeTrailingAmpersands(url) {
    while (url.endsWith("&")) {
      url = url.slice(0, -1);
    }
    return url;
  }
  const modifiedURL = removeTrailingAmpersands(modifiedApiUrlFilterWith);

  function removeFirstAmpersand(modifiedURL) {
    if (modifiedURL.startsWith("&")) {
      return modifiedURL.slice(1);
    }
    return modifiedURL;
  }
  const modifiedApiUrlFilterWithString = removeFirstAmpersand(modifiedURL);

  // useEffect(() => {
  //   setPage(Number(location.search.split("=")[1].split("&")[0]));
  //   setPageSize(Number(location.search.split("=")[2].split("&")[0]));
  //   setInputValue(Number(location.search.split("=")[2].split("&")[0]));
  // }, [pageSize, location, page]);

  const muiStyle = {
    CustomTableShow: {
      maxHeight: apiData.length > 201 ? "6189px" : "auto",
      overflow: apiData.length > 201 ? "auto" : "visible",
    },
  };

  const updateSearchParams = (searchObj) => {
    const allVars = getUrlVars();
    let searchString = "";
    if (allVars) {
      for (var k in allVars) {
        if (allVars.hasOwnProperty(k)) {
          if (searchObj[k] || allVars[k]) {
            let value = searchObj[k] || allVars[k];
            if (value && value.length !== 0) {
              searchString += `${searchString === "" ? "?" : "&"}${k}=${value}`;
            }
          }
        }
      }
    }

    for (const key in searchObj) {
      if (Object.hasOwnProperty.call(searchObj, key)) {
        const element = searchObj[key];
        if (!allVars[key] && element && element.length !== 0) {
          searchString += `${searchString === "" ? "?" : "&"}${key}=${element}`;
        }
      }
    }
    history.replace(`filter${searchString}`);
    // fetchData();
  };

  const [pageOption, setPageOption] = useState({
    // size: pageSize,
    // page: 0,
    sic: "",
    ticker: "",
    issuer: "",
    reporter: "",
    transaction_code: "",
  });
  const getTicker = (id, type) => {
    setIsTickerSearch(true);
    const request = {
      ...pageOption,
      [type]: id,
    };
    updateSearchParams(request);
  };
  function isOverflown(element) {
    return (
      element.scrollHeight > element.clientHeight ||
      element.scrollWidth > element.clientWidth
    );
  }
  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value, params } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);
    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      // if(params.field==="ticker"){
      //   cellValue.current.value=params.row.issuer_name
      // }

      setShowPopper(true);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
          setShowFullCell(false);
        }
      }

      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);
    return (
      <div
        ref={wrapper}
        // className={classes.root}
        style={{
          alignItems: "center",
          lineHeight: "24px",
          width: "100%",
          height: "100%",
          position: "relative",
          display: "flex",
          "& .cellValue": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div
          ref={cellDiv}
          style={{
            height: 1,
            width,
            display: "block",
            position: "absolute",
            top: 0,
          }}
        />
        <div
          ref={cellValue}
          className="cellValue cursor_pointer text-underline"
          style={{
            textDecoration: "underline",
            color: params.field === "ticker" && "blue",
          }}
        >
          {value}
        </div>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width, marginLeft: -17 }}
          >
            <Paper
              elevation={1}
              style={{ minHeight: wrapper.current.offsetHeight - 3 }}
            >
              <Typography variant="body2" style={{ padding: 8 }}>
                <span
                  className="cursor_pointer text-underline"
                  id={params.id}
                  onClick={(e) => {
                    if (params.field === "reporter_name") {
                      getTicker(params.row.reporter_cik, "reporter");
                      localStorage.setItem(
                        "reporter",
                        params.row.reporter_name
                      );
                    } else if (params.field === "issuer_name") {
                      getTicker(params.row.issuer_cik, "issuer");
                      localStorage.setItem("issuer", params.row.issuer_name);
                    } else {
                      getTicker(params.row.ticker, "ticker");
                    }
                    // getTicker(
                    //   // params.row.issuer,
                    //   params.field === "reporter_name"
                    //     ? params.row.reporter_cik
                    //     : params.row.issuer_cik,
                    //   params.field === "reporter_name" ? "reporter" : "issuer"
                    // );
                  }}
                >
                  {" "}
                  {/* {value} */}
                  {params.field === "ticker"
                    ? (cellValue.current.value = params.row.issuer_name)
                    : value}
                </span>
              </Typography>
            </Paper>
          </Popper>
        )}
      </div>
    );
  });

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
  };
  function renderCellExpand(params) {
    return (
      <GridCellExpand
        value={params.value ? params.value.toString() : ""}
        width={params.colDef.width}
        params={params}
      />
    );
  }

  renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.object.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool,
    ]),
  };
  const columns = [
    // {
    //   field: "trans_type",
    //   headerName: "Type",
    //   // width: 100,
    //   flex: 0.2,
    //   align: "center",
    //   filterable: false,
    //   disableColumnMenu: true,
    // },

    {
      type: "date",
      field: "reported_dt",
      headerName: "Report date - (SEC link)",
      // headerName: "Filing date",
      renderCell: (params) => (
        <a
          href={params.row.html_form4_link}
          className="linkStyle"
          target="_blank"
        >
          <span style={{ textDecoration: "underline", color: "blue" }}>
            {" "}
            {new Date(params.value).toLocaleDateString()}
          </span>
        </a>
      ),
      flex: 0.17,
      align: "center",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      hide: false,
    },
    {
      field: "transaction_code",
      headerName: "Type",
      valueFormatter: (params) => {
        const valueFormatted = params.value === "S" ? "Sale" : "Purchases";
        return `${valueFormatted}`;
      },
      align: "left",
      flex: 0.1,
      type: "string",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "ticker",
      headerName: "Ticker",
      renderCell: renderCellExpand,
      flex: 0.1,
      align: "left",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      // disableColumnMenu: true,
    },

    // {
    //   field: "issuer_name",
    //   headerName: "Issuer",
    //   renderCell: renderCellExpand,
    //   // renderCell: (params) => (
    //   //   // <a href={params.value} style={linkStyle} target="_blank">
    //   //   <span
    //   //     className="cursor_pointer text-underline"
    //   //     id={params.id}
    //   //     onClick={(e) => {
    //   //       getTicker(params.row.issuer, "issuer");
    //   //     }}
    //   //   >
    //   //     {params.value}
    //   //   </span>
    //   //   // </a>
    //   // ),
    //   align: "left",
    //   // align: "center",
    //   flex: 0.25,
    //   filterable: false,
    //   disableColumnMenu: true,
    //   sortable: false,
    //   // disableColumnMenu: true,
    // },
    {
      field: "reporter_name",
      headerName: "Reporter Name",
      // renderCell: renderCellExpand,

      renderCell: (params) => (
        // <a href={params.value} style={linkStyle} target="_blank">
        <span
          className="cursor_pointer text-underline"
          style={{ textDecoration: "underline", color: "blue" }}
          id={params.id}
          onClick={(e) => {
            getTicker(params.row.reporter_cik, "reporter");
          }}
        >
          {params.value}
        </span>
        // </a>
      ),
      // align: "center",
      align: "left",
      flex: 0.22,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      // disableColumnMenu: true,
    },

    {
      field: "officer",
      headerName: "Title",
      flex: 0.14,
      valueFormatter: (params) => {
        var valueFormatted = "";
        if (params.row.reporter_is_director === "1") {
          valueFormatted += "Dir, ";
        }
        if (params.row.reporter_is_officer === "1") {
          // valueFormatted += " Officer";
          valueFormatted += params.row.reporter_title;
        }
        if (params.row.reporter_is_tenpercentowner === "1") {
          valueFormatted += " 10% owner, ";
        }
        if (params.row.reporter_is_other === "1") {
          valueFormatted += " Other";
        }
        if (params.row.title) {
          valueFormatted += " , " + params.row.title;
        }

        valueFormatted = valueFormatted.trim().toUpperCase();
        if (valueFormatted.slice(-1) === ",") {
          valueFormatted = valueFormatted.slice(0, valueFormatted.length - 1);
        }

        return `${valueFormatted}`;
      },
      align: "left",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      // disableColumnMenu: true,
    },

    {
      field: "quantity",
      // type: "number",
      headerName: "Quantity",
      valueFormatter: (params) => {
        const valueFormatted = params.value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${valueFormatted}`;
      },
      // align: "center",
      align: "left",
      flex: 0.13,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      // disableColumnMenu: false,
    },
    {
      field: "price",
      // type: "number",
      headerName: "Price",
      valueFormatter: (params) => {
        const valueFormatted = params.value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `$${valueFormatted}`;
      },
      // align: "center",
      align: "left",
      flex: 0.08,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      // disableColumnMenu: false,
    },
    {
      field: "total",
      // type: "number",
      headerName: "Total",
      valueFormatter: (params) => {
        const valueFormatted = params.value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `$${valueFormatted}`;
      },
      flex: 0.12,
      align: "left",
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      // disableColumnMenu: false,
    },

    {
      field: "post_transaction_amounts",
      valueFormatter: (params) => {
        const valueFormatted = params.value
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return `${valueFormatted}`;
      },
      headerName: "Owned post transaction",
      align: "left",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  const handleKeyDownPage = (event, type) => {
    if (type == "onBlur") {
      setPage(1);
      setPageSize(parseInt(inputValue));
    } else {
      if (event.key === "Enter") {
        event.preventDefault();
        setPage(1);
        setPageSize(parseInt(inputValue));
      }
    }
  };

  const handleNewPageChange = (newPageSize, page) => {
    if (tableType === "individual") {
      history.push(
        `/individual/page_type=individual/filter?${modifiedApiUrlFilterWithString}`
      );
    } else if (tableType === "investors") {
      history.push(
        `/investors/page_type=investors/filter?${modifiedApiUrlFilterWithString}`
      );
    } else if (tableType === "dashboard") {
      history.push(`/filter?${modifiedApiUrlFilterWithString}`);
    }
  };

  const allowValues = inputValue < 101 && inputValue > 0;

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  console.log(allowValues, inputValue, pageSize, "pageSizeee");
  return (
    <>
      {/* <Row className="mb_1"><marquee><span style={{ fontSize: "20px" }}>Click "report date" to view the SEC link</span></marquee></Row> */}
      <Row style={{ width: "100%" }}>
        <DataGrid
          // className="dashboard_grid"
          rows={apiData}
          rowHeight={43}
          autoHeight={true}
          columns={columns}
          page={page - 1}
          // hideFooterPagination={true}
          // hideFooterRowCount={true}
          // hideFooter={true}
          // hideFooterSelectedRowCount={true}
          // autoPageSize="true"
          autoPageSize
          pageSize={pageSize}
          style={muiStyle.CustomTableShow}
          // rowsPerPageOptions={[20, 27, 50, 100]}
          rowLength={totalRecord}
          onPageChange={(pageNumber) => {
            setPage(pageNumber + 1);
          }}
          // onPageSizeChange={(newPageSize) => {
          //   setPageSize(newPageSize);
          //   handleNewPageChange(newPageSize, page);
          // }}
        />
        <Row
          style={{
            position: "absolute",
            right: "290px",
            bottom: "25px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              display:
                pageSize === 25 || pageSize === 50 || pageSize === 100
                  ? "none"
                  : "block",
            }}
          >
            Rows per page:
          </span>
          <form onSubmit={!allowValues && handleSubmit}>
            <Input
              required
              type="number"
              value={inputValue}
              min="1"
              max="100"
              onChange={(e) => setInputValue(e.target.value)}
              onKeyDown={(event) =>
                allowValues && handleKeyDownPage(event, "onEnter")
              }
              onBlur={(event) =>
                allowValues && handleKeyDownPage(event, "onBlur")
              }
              style={{ width: "100px", borderRadius: "22px", height: "44px" }}
            />
          </form>
        </Row>
      </Row>
    </>
  );
};

export default CustomTable;
