import React, { useState } from "react";
import { Row, Col, Button, Avatar, Space } from "antd";
import { StarOutlined, CopyOutlined, TrophyOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
const baseUrl = process.env.REACT_APP_BASE_URL;
const stripeKey = process.env.REACT_APP_STRIPE_KEY;
const stripePromise = loadStripe(stripeKey);
const PaymentModal = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  const handleClick1 = async (value) => {
    const id = {
      price_id: value,
      format: "json",
    };
    const response = await axios.post(`${baseUrl}create_checkout_session`, id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (response.data.id) {
      const stripe = await stripePromise;
      // eslint-disable-next-line
      const { error } = await stripe
        .redirectToCheckout({
          sessionId: response.data.id,
        })
        .then(function (result) {
          console.log(result.error.message);
        });
    }
  };
  return (
    <>
      <Row
        className="pd_20"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Col
          style={{ textAlign: "center" }}
          className="modal-border"
          xs={20}
          md={11}
          xl={11}
        >
          <Row className="modalBG" style={{ justifyContent: "center" }}>
            <Col lg={12} xl={12}>
              <Row
                className="font-28 font_weight600"
                style={{ justifyContent: "center" }}
              >
                Fast Track Winter Sale Starting at $4.99
              </Row>

              <Row
                className="font-20 pb_5"
                style={{ justifyContent: "center" }}
              >
                Per Month For Yearly
              </Row>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col style={{ textAlign: "center" }}>
              <Row
                className="font-28 font_weight700"
                style={{ justifyContent: "center" }}
              >
                $5.99/mo
              </Row>
              <Row className="font-20" style={{ justifyContent: "center" }}>
                {" "}
                Billed at{" "}
                <span className="font-20 font_weight700"> $17.97 </span>
                quarterly
              </Row>
            </Col>
          </Row>
          <Row
            className="mt_1"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <Space direction="vertical">
              {/* <Segmented
                defaultValue="0"
                options={[
                  {
                    label: (
                      <div>
                        <Avatar
                          size="large"
                          style={{ backgroundColor: " light gray" }}
                          icon={<StarOutlined />}
                        />
                        <div>Yearly</div>
                        <div>$4.99/mo</div>
                      </div>
                    ),
                    value: "Yearly",
                  },
                  {
                    label: (
                      <div>
                        <Avatar
                          size="large"
                          style={{ backgroundColor: "#f56a00" }}
                          icon={<StarOutlined />}
                        ></Avatar>
                        <div>Quarterly</div>
                        <div>$5.99/mo</div>
                      </div>
                    ),
                    value: "Quarterly",
                  },
                  {
                    label: (
                      <div>
                        <Avatar
                          size="large"
                          style={{ backgroundColor: " light gray" }}
                          icon={<StarOutlined />}
                        />
                        <div>Monthly</div>
                        <div>$7.95/mo</div>
                      </div>
                    ),
                    value: "Monthly",
                  },
                ]}
              /> */}
            </Space>
          </Row>
          {/* <NavLink to="/stripe"> */}
          <Button
            className="buy-now-btn"
            onClick={() => handleClick1("price_1IrRnOKNWNPlRrHRNyJUlBTI")}
          >
            Buy Now
          </Button>
          {/* </NavLink> */}
          <Row className="font-20 mt_5" style={{ justifyContent: "center" }}>
            {" "}
            <span className="font-20 font_weight700"> 10% OFF </span> code for
            yearly and quarterly.{" "}
          </Row>
          <Button
            onClick={handleClick}
            type="dashed"
            className="mt_1"
            icon={<CopyOutlined />}
          >
            smart10
          </Button>
          {isCopied ? <p className="success-msg">Successfully copied</p> : null}

          <Row
            className="font-20 mt_1 font_weight700 font-color"
            style={{ justifyContent: "center" }}
          >
            {" "}
            PRE-K TO 8TH GRADE
          </Row>
        </Col>
        <Col sm={0} md={1} xl={1} />
        <Col
          style={{ textAlign: "center" }}
          className="modal-border"
          xs={20}
          md={11}
          xl={11}
        >
          <Row className="modalBG" style={{ justifyContent: "center" }}>
            <Col lg={12} xl={11}>
              <Row
                className="font-28 font_weight600"
                style={{ justifyContent: "center" }}
              >
                Most Popular
              </Row>
              <Row
                className="font-25 font_weight600"
                style={{ justifyContent: "center" }}
              >
                Fast Track Extra Winter Sale Only at $129
              </Row>
              <Row style={{ justifyContent: "center" }}>(Reg.$899)</Row>
              <Row
                className="font-20 pb_5"
                style={{ justifyContent: "center" }}
              >
                Lifetime Membership
              </Row>
            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col style={{ textAlign: "center" }}>
              <Row
                className="font-28 font_weight700"
                style={{ justifyContent: "center" }}
              >
                $129.00
              </Row>
              <Row className="font-20" style={{ justifyContent: "center" }}>
                {" "}
                Billed at{" "}
                <span className="font-20 font_weight700"> $129.00 </span> one
                time only
              </Row>
              <Row
                className="font-28 font_weight700 font-color"
                style={{ justifyContent: "center" }}
              >
                <Col lg={20} xl={20}>
                  Lifetime Membership PLUS FREE one-to-one Tutoring
                </Col>
              </Row>
              {/* <NavLink to="/stripe"> */}
              <Button
                className="buy-now-btn"
                onClick={() => handleClick1("price_1IrRnOKNWNPlRrHRNyJUlBTI")}
              >
                Buy Now
              </Button>
              {/* </NavLink> */}
              <Row
                className="font-20 font_weight700 font-color"
                style={{ justifyContent: "center" }}
              >
                {" "}
                PRE-K TO 8TH GRADE
              </Row>
            </Col>
          </Row>
          <Row className="font-18">
            Includes Lifetime Membership to our online program PLUS one FREE
            one-to-one Tutoring Assesment!{" "}
          </Row>
          <Space>
            <Row
              style={{ justifyContent: "center", alignItems: "center" }}
              className="font-18 font_weight700"
            >
              <TrophyOutlined className="font-20 font-color" />
              Winter Sale ONLY $129 one-time payment
            </Row>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default PaymentModal;
