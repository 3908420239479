import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import AboutUsImg from "../assets/AboutUs.svg";
import AmiableWorkforce from "../assets/AmiableWorkforce.png";
import FocusOnQuality from "../assets/FocusOnQuality.png";
import InternationalExplorer from "../assets/InternationalExplorer.png";
import OurApproach from "../assets/OurApproach.png";
import OurExperience from "../assets/OurExperience.png";
import PeopleDexterity from "../assets/PeopleDexterity.png";
import Footer from "./Footer";
import "./Styles/ContactUs.scss";
import "./Styles/Footer.scss";

const Context = React.createContext({ name: "Default" });
const baseUrl = process.env.REACT_APP_BASE_URL;
const { TextArea } = Input;

const ContactUs = () => {
  const [isLoading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const cardData = [
    {
      id: 1,
      title: "Our Approach",
      image: OurApproach,
      description:
        "Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown.Lorem Ipsum is simply dummy.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown",
    },
    {
      id: 2,
      title: "Our Experience",
      image: OurExperience,
      description:
        "Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown.Lorem Ipsum is simply dummy.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown",
    },
    {
      id: 3,
      title: "Focus On Quality",
      image: FocusOnQuality,
      description:
        "Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown.Lorem Ipsum is simply dummy.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown",
    },
    {
      id: 4,
      title: "International Explorer",
      image: InternationalExplorer,
      description:
        "Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown.Lorem Ipsum is simply dummy.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown",
    },
    {
      id: 5,
      title: "People Dexterity",
      image: PeopleDexterity,
      description:
        "Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown.Lorem Ipsum is simply dummy.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown",
    },
    {
      id: 6,
      title: "Amiable Workforce",
      image: AmiableWorkforce,
      description:
        "Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown.Lorem Ipsum is simply dummy.Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown",
    },
  ];

  const submitFeedback = (values) => {
    setLoading(true);
    fetch(`${baseUrl}feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://premsite.com/",
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data && data.code === 200) {
          openNotification("success", "Feedback has been added successfully!");
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        openNotification("error", "Something went wrong!");
      });
  };

  const openNotification = (type, description) => {
    api[type]({
      message: type.charAt(0).toUpperCase() + type.slice(1),
      description,
      placement: "topRight",
    });
  };

  return (
    <Context.Provider>
      {contextHolder}
      {/* <Row className="about_us_page">
        <Col lg={{ span: 18, offset: 3 }} xs={{ span: 20, offset: 2 }}>
          <Typography className="about_us_page_title">About US</Typography>
          <Row>
            <Col lg={12} xs={24}>
              <Typography>
                Lorem Ipsum is simply dummy. Lorem Ipsum has been the industry's
                standard dummy text ever since the 1500s, when an unknown
                printer took a galley of type and scrambled it to make a type
                specimen book. It has survived not only five centuries, but also
                the leap into electronic typesetting, remaining essentially
                unchanged. It was popularised in the 1960s with the release of
                Letraset sheets containing Lorem Ipsum passages, and more
                recently with desktop publishing software like Aldus PageMaker
                including versions of Lorem Ipsum.Lorem Ipsum is simply dummy
                text of the printing and typesetting industry. Lorem Ipsum has
                been the industry's standard dummy text ever since the 1500s,
                when an unknown printer took a galley of type and scrambled it
                to make a type specimen book. It has survived not only five
                centuries, but also the leap into electronic typesetting,
                remaining essentially unchanged. It was popularised in the 1960s
                with the release of Letraset sheets containing Lorem Ipsum
                passages, and more recently with desktop publishing software
                like Aldus PageMaker including versions of Lorem Ipsum.Lorem
                Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged. It was
                popularised in the 1960s with the release of Letraset sheets
                containing Lorem Ipsum passages, and more recently with desktop
                publishing software like Aldus PageMaker including versions of
                Lorem Ipsum.
              </Typography>
            </Col>
            <Col
              lg={{ span: 11, offset: 1 }}
              xs={24}
              className="about_us_image_part"
            >
              <img
                src={AboutUsImg}
                alt=""
                width="100%"
                height="100%"
                style={{ objectFit: "cover" }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="what_we_do">
        <Col lg={{ span: 18, offset: 3 }} xs={{ span: 20, offset: 2 }}>
          <Typography className="about_us_page_title">What We Do</Typography>
          <Row gutter={16}>
            {cardData.map((item, index) => (
              <Col lg={8} sm={12} xs={24} key={index}>
                <Card className="about_us_page_card">
                  <Row align="middle" className="contact_us_page_card">
                    <img
                      src={item.image}
                      alt=""
                      height="30px"
                      width="30px"
                      style={{ marginRight: "14px" }}
                    />
                    <Typography className="about_us_page_card_title">
                      {item.title}
                    </Typography>
                  </Row>
                  <Typography className="contact_us_page_card">{item.description}</Typography>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row> */}
      <Row className="about_us_page">
        <Col lg={{ span: 18, offset: 3 }} xs={{ span: 20, offset: 2 }}>
          <Typography className="about_us_page_title">Get in touch</Typography>
          <Form
            name="basic"
            className="about_us_form"
            layout="vertical"
            onFinish={submitFeedback}
          >
            <Row gutter={20}>
              <Col md={8} sm={24}>
                <Form.Item
                  label="First name"
                  name="firstname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your firstName!",
                    },
                  ]}
                >
                  <Input placeholder="First name" />
                </Form.Item>
              </Col>
              <Col md={8} sm={24}>
                <Form.Item
                  label="Last name"
                  name="lastname"
                  rules={[
                    {
                      required: true,
                      message: "Please input your lastName!",
                    },
                  ]}
                >
                  <Input placeholder="Last name" />
                </Form.Item>
              </Col>
              <Col md={8} sm={24}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Message"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Message!",
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="How could this article be improved?"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  className="send_message_button"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Footer />
    </Context.Provider>
  );
};

export default ContactUs;
