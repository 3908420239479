import { Row, Col } from "antd";
import React from "react";
import Footer from "./Footer";
import "./Styles/Footer.scss";
const Terms = () => {
  return (
    <>
      <Row align="middle" justify="center" style={{ width: "100%",marginBottom:"4em" }}>
        <Col span={16}>
          <h1>Terms & Conditions</h1>
          1. Terms <br />
          <br />
          By accessing the website at{" "}
          <a className="textClr" href="http://100ktrading.info" target="_blank">
            http://100ktrading.info
          </a>{" "}
          , you are agreeing to be bound by these terms of service, all
          applicable laws and regulations, and agree that you are responsible
          for compliance with any applicable local laws. If you do not agree
          with any of these terms, you are prohibited from using or accessing
          this site. The materials contained in this website are protected by
          applicable copyright and trademark law.
          <br />
          <br />
          2.Use License
          <br />
          <br /> Permission is granted to temporarily download one copy of the
          materials (information or software) on 100ktrading&#39;s website for
          personal, non-commercial transitory viewing only. This is the grant of
          a license, not a transfer of title, and under this license you may
          not:
          <br />
          <Row>
            <Col span={24} offset={[2]}>
              modify or copy the materials;
              <br />
              <Row>
                use the materials for any commercial purpose, or for any public
                display (commercial or non-commercial);
              </Row>
              <Row>
                attempt to decompile or reverse engineer any software contained
                on 100ktrading&#39;s website;
              </Row>
              <Row>
                remove any copyright or other proprietary notations from the
                materials; or
              </Row>
              <Row>
                transfer the materials to another person or &quot;mirror&quot;
                the materials on any other server.
              </Row>
            </Col>
          </Row>
          <br />
          This license shall automatically terminate if you violate any of these
          restrictions and may be terminated by 100ktrading at any time. Upon
          terminating your viewing of these materials or upon the termination of
          this license, you must destroy any downloaded materials in your
          possession whether in electronic or printed format.
          <br />
          <br /> 3.Disclaimer <br />
          <br />
          The materials on 100ktrading&#39;s website are provided on an &#39;as
          is&#39; basis. 100ktrading makes no warranties, expressed or implied,
          and hereby disclaims and negates all other warranties including,
          without limitation, implied warranties or conditions of
          merchantability, fitness for a particular purpose, or non-infringement
          of intellectual property or other violation of rights.
          <Row style={{ textIndent: "6%" }}>
            Further, 100ktrading does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such
            materials or on any sites linked to this site.
          </Row>{" "}
          <br /> 4. Limitations
          <br />
          <br /> In no event shall 100ktrading or its suppliers be liable for
          any damages (including, without limitation, damages for loss of data
          or profit, or due to business interruption) arising out of the use or
          inability to use the materials on 100ktrading&#39;s website, even if
          100ktrading or a 100ktrading authorized representative has been
          notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
          <br />
          <br /> 5. Accuracy of materials
          <br />
          <br /> The materials appearing on 100ktrading&#39;s website could
          include technical, typographical, or photographic errors. 100ktrading
          does not warrant that any of the materials on its website are
          accurate, complete or current. 100ktrading may make changes to the
          materials contained on its website at any time without notice. However
          100ktrading does not make any commitment to update the materials.{" "}
          <br />
          <br />
          6. Links <br />
          <br />
          100ktrading has not reviewed all of the sites linked to its website
          and is not responsible for the contents of any such linked site. The
          inclusion of any link does not imply endorsement by 100ktrading of the
          site. Use of any such linked website is at the user&#39;s own risk.
          <br />
          <br /> 7. Modifications
          <br />
          <br />
          100ktrading may revise these terms of service for its website at any
          time without notice. By using this website you are agreeing to be
          bound by the then current version of these terms of service. <br />
          <br />
          8. Governing Law <br />
          <br />
          These terms and conditions are governed by and construed in accordance
          with the laws of Maryland and you irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location.
        </Col>
      </Row>
      <Row style={{ width: "100%", bottom: "0" }}>
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </>
  );
};

export default Terms;
