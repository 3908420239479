import { InfoCircleFilled } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Spin,
  Tooltip,
} from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, NavLink } from "react-router-dom";
import {
  addCommas,
  authLoginChecker,
  checkIsSame,
  removeCommas,
  removeNonNumeric,
} from "../helper/commonFunction";
import "./Styles/Dashboard.scss";
import Cookies from "js-cookie";

const Filter = (props) => {
  const { checkedList, keyName, setPageOption, pageOption } = props;
  console.log(pageOption, "pageOPTION---");
  const history = useHistory();
  const [form] = Form.useForm();

  let fetchURLData = props.fetchSearchParams(pageOption);
  const { RangePicker } = DatePicker;
  function objectToQueryString(obj) {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  }

  const handleCancel = () => {
    form.resetFields();
    localStorage.removeItem("reporter");
    // setPageOption({
    //   page: "1",
    //   size: pageOption.size,
    // });
    props.setFilteredData &&
      props.setFilteredData({
        qty_min: 5,
        qty_max: 5000000,
        price_min: 1,
        price_max: 10000,
      });
    history.replace(`filter`);
    localStorage.removeItem("issuer");
    localStorage.removeItem("reporter");
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  useEffect(() => {
    if (props.isResetFilter) {
      form.setFieldsValue({
        qty_max: null,
        qty_min: null,
        // page: "1",
        reporter: [],
        // size: pageOption.size,
        ticker: null,
        total_min: "",
        transaction_code: "",
        officer: null,
        price_max: null,
        price_min: null,
        reported_dt_max: null,
        reported_dt_min: null,
        total_max: null,
        traded_dt_max: null,
        traded_dt_min: null,
      });
    }
  }, [props.isResetFilter]);
  const CurLocation = useLocation();
  const onFinish = (values) => {
    values.qty_max = removeCommas(quantityValue.max);
    values.qty_min = removeCommas(quantityValue.min);
    values.price_max = removeCommas(priceValue.max);
    values.price_min = removeCommas(priceValue.min);
    values.total_max = removeCommas(totalValue.max);
    values.total_min = removeCommas(totalValue.min);
    values.filing_dt_max = values.filing_dt_min
      ? moment(values.filing_dt_min[1]._d).format("YYYYMMDD")
      : undefined;
    values.filing_dt_min = values.filing_dt_min
      ? moment(values.filing_dt_min[0]._d).format("YYYYMMDD")
      : undefined;
    values.traded_dt_max = values.traded_dt_min
      ? moment(values.traded_dt_min[1]._d).format("YYYYMMDD")
      : undefined;
    values.traded_dt_min = values.traded_dt_min
      ? moment(values.traded_dt_min[0]._d).format("YYYYMMDD")
      : undefined;
    values.reported_dt_max = values.reported_dt_min
      ? moment(values.reported_dt_min[1]._d).format("YYYYMMDD")
      : undefined;
    values.reported_dt_min = values.reported_dt_min
      ? moment(values.reported_dt_min[0]._d).format("YYYYMMDD")
      : undefined;
    const filteredValue = _.omitBy(values, _.isUndefined);

    function customizer(objValue, srcValue) {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    }
    const latestData = _.mergeWith(
      // { page: "1", size: pageOption.size },
      filteredValue,
      customizer
    );

    const url = new URL(window.location.href);

    const filteredValues =
      latestData?.transaction_code?.length == 2
        ? _.omit(latestData, "transaction_code")
        : latestData;
    let dataWithSic;
    if (keyName == "sic") {
      dataWithSic =
        checkedList.length > 0
          ? { ...filteredValues, sic: checkedList }
          : filteredValues;
    } else {
      dataWithSic =
        checkedList.length > 0
          ? { ...filteredValues, reporter: checkedList }
          : filteredValues;
    }

    for (const key in dataWithSic) {
      if (dataWithSic[key] === null) {
        delete dataWithSic[key];
      }
    }
    if (checkIsSame(url.searchParams.get("sic")?.split(","), dataWithSic.sic)) {
      delete dataWithSic.sic;
    }
    const queryString = objectToQueryString(dataWithSic);
    if (CurLocation.pathname === "/investors/filter") {
      history.replace(`page_type=investors/filter?${queryString}`);
    } else if (CurLocation.pathname === "/individual/filter") {
      history.replace(`page_type=individual/filter?${queryString}`);
    } else {
      history.replace(`filter?${queryString}`);
    }
  };

  const [totalValue, setTotalValue] = useState({
    min: fetchURLData?.total_min ? addCommas(fetchURLData?.total_min) : null,
    max: fetchURLData?.total_max ? addCommas(fetchURLData?.total_max) : null,
  });
  const [quantityValue, setQuantityValue] = useState({
    min: fetchURLData?.qty_min ? addCommas(fetchURLData?.qty_min) : null,
    max: fetchURLData?.qty_max ? addCommas(fetchURLData?.qty_max) : null,
  });
  const [priceValue, setPriceValue] = useState({
    min: fetchURLData?.price_min ? addCommas(fetchURLData?.price_min) : null,
    max: fetchURLData?.price_max ? addCommas(fetchURLData?.price_max) : null,
  });

  const handleChange = (event, type, subType) => {
    if (type === "Price") {
      subType === "min"
        ? setPriceValue({
            ...priceValue,
            min: addCommas(removeNonNumeric(event.target.value)),
          })
        : setPriceValue({
            ...priceValue,
            max: addCommas(removeNonNumeric(event.target.value)),
          });
    }
    if (type === "Quantity") {
      subType === "min"
        ? setQuantityValue({
            ...quantityValue,
            min: addCommas(removeNonNumeric(event.target.value)),
          })
        : setQuantityValue({
            ...quantityValue,
            max: addCommas(removeNonNumeric(event.target.value)),
          });
    }
    if (type === "Total") {
      subType === "min"
        ? setTotalValue({
            ...totalValue,
            min: addCommas(removeNonNumeric(event.target.value)),
          })
        : setTotalValue({
            ...totalValue,
            max: addCommas(removeNonNumeric(event.target.value)),
          });
    }
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.filterLoading) {
      setLoading(true);
    } else {
      if (fetchURLData?.reported_dt_min && fetchURLData?.traded_dt_min) {
        form.setFieldsValue({
          reported_dt_min: [
            moment(
              `${fetchURLData?.reported_dt_min?.substr(
                0,
                4
              )}-${fetchURLData?.reported_dt_min?.substr(
                4,
                2
              )}-${fetchURLData?.reported_dt_min?.substr(6, 2)}`
            ),
            moment(
              `${fetchURLData?.reported_dt_max?.substr(
                0,
                4
              )}-${fetchURLData?.reported_dt_max?.substr(
                4,
                2
              )}-${fetchURLData?.reported_dt_max?.substr(6, 2)}`
            ),
          ],
          traded_dt_min: [
            moment(
              `${fetchURLData?.traded_dt_min?.substr(
                0,
                4
              )}-${fetchURLData?.traded_dt_min?.substr(
                4,
                2
              )}-${fetchURLData?.traded_dt_min?.substr(6, 2)}`
            ),
            moment(
              `${fetchURLData?.traded_dt_max?.substr(
                0,
                4
              )}-${fetchURLData?.traded_dt_max?.substr(
                4,
                2
              )}-${fetchURLData?.traded_dt_max?.substr(6, 2)}`
            ),
          ],
        });
      } else if (fetchURLData?.reported_dt_min) {
        form.setFieldsValue({
          reported_dt_min: [
            moment(
              `${fetchURLData?.reported_dt_min?.substr(
                0,
                4
              )}-${fetchURLData?.reported_dt_min?.substr(
                4,
                2
              )}-${fetchURLData?.reported_dt_min?.substr(6, 2)}`
            ),
            moment(
              `${fetchURLData?.reported_dt_max?.substr(
                0,
                4
              )}-${fetchURLData?.reported_dt_max?.substr(
                4,
                2
              )}-${fetchURLData?.reported_dt_max?.substr(6, 2)}`
            ),
          ],
        });
      } else if (fetchURLData?.traded_dt_min) {
        form.setFieldsValue({
          traded_dt_min: [
            moment(
              `${fetchURLData?.traded_dt_min?.substr(
                0,
                4
              )}-${fetchURLData?.traded_dt_min?.substr(
                4,
                2
              )}-${fetchURLData?.traded_dt_min?.substr(6, 2)}`
            ),
            moment(
              `${fetchURLData?.traded_dt_max?.substr(
                0,
                4
              )}-${fetchURLData?.traded_dt_max?.substr(
                4,
                2
              )}-${fetchURLData?.traded_dt_max?.substr(6, 2)}`
            ),
          ],
        });
      } else {
        // console.log(fetchURLData);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, []);
  const user_logged_in = !authLoginChecker();
  return (
    <Card className="dahsboardFormCard" bodyStyle={{ padding: "0px 0px" }}>
      {loading ? (
        <Row justify="center" align="middle" className="dahsboardFormCardRow">
          <Spin size="large" />
        </Row>
      ) : (
        <Form
          name="basic"
          layout="horizontal"
          form={form}
          initialValues={fetchURLData}
          onFinish={onFinish}
          className={
            !user_logged_in
              ? "dahsboardFormFilter"
              : "dahsboardFormFilterGrayColor"
          }
        >
          {user_logged_in && (
            <Row className="mb_1">
              <Col span={24}>
                <Alert
                  message={
                    <span className="link_button">
                      {`To enable the filter login first `}
                      {/* <NavLink
                        exact={true}
                        to="/register"
                        className="link_text"
                      >
                        {" "}
                        register
                      </NavLink> */}
                      <NavLink exact={true} to="/login" className="link_text">
                        login
                      </NavLink>
                    </span>
                  }
                  type="warning"
                  showIcon
                  closable
                />
              </Col>
            </Row>
          )}
          <Row
            gutter={16}
            className={
              !user_logged_in ? "dahsboardFormRow" : "dahsboardFormRowGrayColor"
            }
          >
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <Card className="card_filter">
                {/* ----Reported date---- */}
                <Row>
                  <span className="dashboardtableReportTitle dahsboardFormLabel">
                    Reported date
                    <Tooltip title="The date transaction was reproted">
                      <InfoCircleFilled className="ml-1 cursor_pointer" />
                    </Tooltip>
                  </span>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="reported_dt_min">
                      <RangePicker
                        disabled={user_logged_in}
                        format="YYYY-MM-DD"
                        className="dashboardInputStyle"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* ----Traded date---- */}
                <Row>
                  <span className="dashboardtableReportTitle dahsboardFormLabel">
                    Traded date
                    <Tooltip title="The date the transaction was traded">
                      <InfoCircleFilled className="ml-1 cursor_pointer" />
                    </Tooltip>
                  </span>
                </Row>
                <Row>
                  <Col span={24}>
                    <Form.Item name="traded_dt_min">
                      <RangePicker
                        format="YYYY-MM-DD"
                        className="dashboardInputStyle"
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <Card className="card_filter">
                {/* ----Price---- */}
                <Row>
                  <span className="dashboardtableReportTitle dahsboardFormLabel">
                    Price
                    <Tooltip title="Pice of the stock">
                      <InfoCircleFilled className="ml-1 cursor_pointer" />
                    </Tooltip>
                  </span>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder={
                          fetchURLData?.price_min
                            ? fetchURLData?.price_min
                            : "Min"
                        }
                        value={priceValue.min}
                        onChange={(e) => handleChange(e, "Price", "min")}
                        className="dashboardInputStyle"
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="dahsboardFormPeriodLine">
                    -
                  </Col>
                  <Col span={11}>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder={
                          fetchURLData?.price_max
                            ? fetchURLData?.price_max
                            : "Max"
                        }
                        value={priceValue.max}
                        className="dashboardInputStyle"
                        onChange={(e) => handleChange(e, "Price", "max")}
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* ---Quantity--- */}
                <Row>
                  <span className="dashboardtableReportTitle dahsboardFormLabel">
                    Quantity
                    <Tooltip title="Quantity of stocks Purchasesd">
                      <InfoCircleFilled className="ml-1 cursor_pointer" />
                    </Tooltip>
                  </span>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder={
                          fetchURLData?.qty_min ? fetchURLData?.qty_min : "Min"
                        }
                        className="dashboardInputStyle"
                        value={quantityValue.min}
                        onChange={(e) => handleChange(e, "Quantity", "min")}
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="dahsboardFormPeriodLine">
                    -
                  </Col>
                  <Col span={11}>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder={
                          fetchURLData?.qty_max ? fetchURLData?.qty_max : "Max"
                        }
                        className="dashboardInputStyle"
                        value={quantityValue.max}
                        onChange={(e) => handleChange(e, "Quantity", "max")}
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={12} xl={8} xxl={6}>
              <Card className="card_filter">
                {/* ---Total--- */}
                <Row>
                  <span className="dashboardtableReportTitle dahsboardFormLabel">
                    Total
                    <Tooltip title="Total value">
                      <InfoCircleFilled className="ml-1 cursor_pointer" />
                    </Tooltip>
                  </span>
                </Row>
                <Row>
                  <Col span={11}>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder={
                          fetchURLData?.total_min
                            ? fetchURLData?.total_min
                            : "Min"
                        }
                        className="dashboardInputStyle"
                        value={totalValue.min}
                        onChange={(e) => handleChange(e, "Total", "min")}
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2} className="dahsboardFormPeriodLine">
                    -
                  </Col>
                  <Col span={11}>
                    <Form.Item>
                      <Input
                        type="text"
                        placeholder={
                          fetchURLData?.total_max
                            ? fetchURLData?.total_max
                            : "Max"
                        }
                        value={totalValue.max}
                        className="dashboardInputStyle"
                        onChange={(e) => handleChange(e, "Total", "max")}
                        disabled={user_logged_in}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* ---Ticker--- */}
                <Row>
                  <Col xs={24} sm={11} className="dahsboardFormCardRow">
                    <Row>
                      <span className="dashboardtableReportTitle dahsboardFormLabel">
                        Ticker
                        <Tooltip title="Stock ticker">
                          <InfoCircleFilled className="ml-1 cursor_pointer" />
                        </Tooltip>
                      </span>
                    </Row>
                    <Row>
                      <Form.Item name="ticker" className="dahsboardFormCardRow">
                        <Input
                          type="text"
                          placeholder="Ticker"
                          className=" dashboardInputStyle"
                          onInput={(e) =>
                            (e.target.value = e.target.value?.toUpperCase())
                          }
                          disabled={user_logged_in}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col xs={0} sm={2}></Col>
                  <Col xs={24} sm={11} className="dahsboardFormCardRow">
                    <Card className="card_filter">
                      <Row>
                        <span className="dashboardtableReportTitle dahsboardFormLabelTransactionType">
                          Transaction Type
                          <Tooltip title="prompt text">
                            <InfoCircleFilled className="ml-1 cursor_pointer" />
                          </Tooltip>
                        </span>
                      </Row>
                      <Form.Item name="transaction_code">
                        <Checkbox.Group className="dashboardtableReportTitle dahsboardFormCardRow">
                          <Row gutter={[10, 10]}>
                            <Col span={24}>
                              <Checkbox
                                value="Purchases"
                                disabled={user_logged_in}
                              >
                                Purchases
                              </Checkbox>
                            </Col>
                            <Col span={24}>
                              <Checkbox value="Sales" disabled={user_logged_in}>
                                Sales
                              </Checkbox>
                            </Col>
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={24} lg={12} xl={24} xxl={6}>
              <Row>
                <Col xs={24} md={13} lg={24} xl={8} xxl={24}>
                  <Card className="card_filter">
                    {/* ---Officer---- */}
                    <Row>
                      <span className="dashboardtableReportTitle dahsboardFormLabel">
                        Officer
                        <Tooltip title="The title of the Purchasesr">
                          <InfoCircleFilled className="ml-1 cursor_pointer" />
                        </Tooltip>
                      </span>
                    </Row>
                    <Form.Item name="officer">
                      <Checkbox.Group className="dashboardtableReportTitle dahsboardFormCardRow">
                        <Row
                          gutter={[10, 10]}
                          className="officerCheckboxRowDashboard"
                        >
                          <Col xxl={8}>
                            <Checkbox
                              value="Director"
                              disabled={user_logged_in}
                            >
                              Director
                            </Checkbox>
                          </Col>
                          <Col xxl={8}>
                            <Checkbox value="Officer" disabled={user_logged_in}>
                              Officer
                            </Checkbox>
                          </Col>
                          <Col xxl={8} className="dahsboardFormCheckBox10Owner">
                            <Checkbox
                              value="10% Owner"
                              disabled={user_logged_in}
                            >
                              10% Owner
                            </Checkbox>
                          </Col>
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Card>
                </Col>
                <Col xs={24} md={11} lg={24} xl={16} xxl={24}>
                  {/* ---Apply---- */}
                  <Row className="applyAndCancelButtonRowDashboard">
                    <Col>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="applyButtondashboard"
                        disabled={user_logged_in}
                      >
                        Apply
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={handleCancel}
                        className="clearButtonDashboard"
                        disabled={user_logged_in}
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Card>
  );
};

export default Filter;
