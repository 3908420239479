import { SearchOutlined } from "@ant-design/icons";
import { Grid } from "@material-ui/core";
import { Checkbox, Col, Input, Modal, Row, Space, Spin, Tag } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  addCommas,
  fetchMainSearchParams,
  getUrlVars,
  updateSearchParams,
  updateSearchParams1,
  updateSearchParams2,
  fetchSearchParams,
  authLoginChecker,
} from "../helper/commonFunction";
import CustomTable from "./CustomTable";
import Filter from "./Filter";
import Footer from "./Footer";
import "./Styles/Dashboard.scss";

const baseUrl = process.env.REACT_APP_BASE_URL;

const Dashboard = (props) => {
  const location = useLocation();
  const [apiData, setApiData] = useState("");
  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isTickerSearch, setIsTickerSearch] = React.useState(false);
  const [pageOption, setPageOption] = useState({
    // size: 100,
    // page: 1,
    sic: "",
    ticker: "",
    issuer: "",
    reporter: "",
    transaction_code: "",
  });

  const log = (type, data) => {
    setTableLoading(true);
    if (type === "officer") {
      const officerData = decodeURIComponent(pageOption.officer)
        .split(",")
        .map((ind) => ind);
      pageOption.officer = encodeURIComponent(
        officerData.filter((datas) => datas !== data)
      );
      setPageOption(pageOption);
      updateSearchParams2(pageOption, history, "");
    } else if (type === "transaction_code") {
      const updateData = _.omit(pageOption, "transaction_code");
      setPageOption(updateData);
      updateSearchParams1(updateData, "transaction_code", "", history, "");
    } else if (type === "ticker") {
      const updateData = _.omit(pageOption, "ticker");
      setPageOption(updateData);
      updateSearchParams1(pageOption, "ticker", "", history, "");
    } else if (type === "issuer") {
      const updateData = decodeURIComponent(pageOption.issuer)
        .split(",")
        .map((ind) => ind);
      pageOption.issuer = updateData.filter((datas) => datas !== data);
      updateSearchParams(pageOption, history, "");
    } else if (type === "reporter") {
      const updateData = _.omit(pageOption, "reporter");
      setPageOption(updateData);
      updateSearchParams1(pageOption, "reporter", "", history, "");
    } else if (type === "filing_dt_min") {
      const updateData = _.omit(pageOption, ["filing_dt_min", "filing_dt_max"]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "filing_dt_min",
        "filing_dt_max",
        history,
        ""
      );
    } else if (type === "reported_dt_min") {
      const updateData = _.omit(pageOption, [
        "reported_dt_min",
        "reported_dt_max",
      ]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "reported_dt_min",
        "reported_dt_max",
        history,
        ""
      );
    } else if (type === "traded_dt_min") {
      const updateData = _.omit(pageOption, ["traded_dt_min", "traded_dt_max"]);
      setPageOption(updateData);
      updateSearchParams1(
        updateData,
        "traded_dt_min",
        "traded_dt_max",
        history,
        ""
      );
    } else if (type === "price_min") {
      const updateData = _.omit(pageOption, ["price_min", "price_max"]);
      setPageOption(updateData);
      updateSearchParams1(updateData, "price_min", "price_max", history, "");
    } else if (type === "qty_min") {
      const updateData = _.omit(pageOption, ["qty_min", "qty_max"]);
      setPageOption(updateData);
      updateSearchParams1(updateData, "qty_min", "qty_max", history, "");
    } else if (type === "total_min") {
      const updateData = _.omit(pageOption, ["total_min", "total_max"]);
      setPageOption(updateData);
      updateSearchParams1(updateData, "total_min", "total_max", history, "");
    } else if (type === "sic") {
      const updateData = decodeURIComponent(pageOption.sic)
        .split(",")
        .map((ind) => ind);
      pageOption.sic = updateData.filter((datas) => parseInt(datas) !== data);
      updateSearchParams(pageOption, history, "");
    }
  };

  const { Search } = Input;
  const [totalRecord, setTotalRecord] = useState(0);
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState({
    qty_min: 5,
    qty_max: 5000000,
    price_min: 1,
    price_max: 10000,
    total_min: 1,
    total_max: 1000000,
  });

  const [filteredIndustry, setFilteredIndustry] = useState([]);
  const getTitleData = async (request) => {
    let queryParamsForAPi;
    if (request.issuer && request.reporter) {
      queryParamsForAPi = `?issuer=${request.issuer}&reporter=${request.reporter}&ticker=${request.ticker}`;
    } else if (request.issuer) {
      queryParamsForAPi = `?issuer=${request.issuer}`;
    } else if (request.reporter) {
      queryParamsForAPi = `?reporter=${request.reporter}`;
    } else if (request.ticker) {
      queryParamsForAPi = `?ticker=${request.ticker}`;
    }
    await axios
      .get(`${baseUrl}titles${queryParamsForAPi ? queryParamsForAPi : ""}`)
      .then((result) => {
        let data = result.data.data;
        setListData(data);
        let filterData = [];
        let array = [];
        if (request && request.sic) {
          array = request.sic.split(",");
          array = array.map((x) => Number(x));
        }
        data.map((list) => {
          array.map((listS) =>
            list.cd === listS ? filterData.push(list) : ""
          );
        });
        setFilteredIndustry(filterData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  const fetchData = () => {
    const request = fetchMainSearchParams(pageOption);
    setTableLoading(true);
    if (!isLoading) {
      fetch(`${baseUrl}data${window.location.search}`, {
        method: "GET",
      })
        .then((resp) => resp.json())
        .then((data) => {
          setTotalRecord(data.count);
          if (data && data.data) {
            if (data && data.count === 0) {
            }

            const uniqueData = removeDuplicatesById(data?.data);
            setApiData(uniqueData);
            const allVars = getUrlVars();
            console.log(allVars, "pageOptionsss");

            let array = [];
            if (request && request.sic) {
              array = request.sic.split(",");
              array = array.map((x) => Number(x));
            }
            setPageOption({
              // ...pageOption,
              ...allVars,
              size: request.size,
              page: request.page,
              sic: array,
            });
            setCheckedList(array);
          }
        })
        .catch((err) => {
          setApiData("err");
        })
        .finally(() => {
          // setIsLoading(false);
          // setTableLoading(false);
          getTitleData(request);
        });
    }
  };

  console.log(pageOption, "pageOption");
  const removeDuplicatesById = (arr) => {
    const uniqueObjects = [];
    const seenIds = new Set();
    arr.forEach((item) => {
      if (!seenIds.has(item.id)) {
        uniqueObjects.push(item);
        seenIds.add(item.id);
      }
    });
    return uniqueObjects;
  };

  const [isResetFilter, setIsResetFilter] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const resetData = () => {
    setIsResetFilter(true);
    setFilterLoading(true);
    setIsTickerSearch(false);
    setPageOption({
      // page: "1",
      reporter: [],
      // size: "100",
      ticker: "",
      total_min: "100000",
      qty_min: null,
      qty_max: null,
      transaction_code: "Purchases",
      officer: null,
      price_max: null,
      price_min: null,
      reported_dt_max: null,
      reported_dt_min: null,
      total_max: null,
      traded_dt_max: null,
      traded_dt_min: null,
    });
    setFilteredData("");
    // history.replace(`filter?size=${pageOption.size}&page=${pageOption.page}`);
    history.replace(
      `/individual/page_type=individual/filter?total_min=100000&transaction_code=Purchases`
    );
    setTimeout(() => {
      setFilterLoading(false);
    }, 1000);
  };

  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(true);
  const [checkAll, setCheckAll] = React.useState(false);

  const handleChange = async (sic) => {
    setCheckedList(sic);
    setIndeterminate(!!sic.length && sic.length < listData.length);
    setCheckAll(sic.length === listData.length);
    const request = {
      ...pageOption,
      sic,
    };
  };

  const modalOpen = localStorage.getItem("open");
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    modalOpen ? setShowModal(false) : setShowModal(true);
  }, []);
  const user_logged_in = !authLoginChecker();
  return (
    <>
      <Row className="dashboard__tablepartFirstRow" style={{ width: "100%" }}>
        {tableLoading ? (
          <Row
            justify="center"
            align="middle"
            className="dashboardmaindiv width_100"
            style={{ height: "100vh" }}
          >
            <Spin size="large" tip="Loading..."></Spin>
          </Row>
        ) : (
          <>
            {/* <Modal
              visible={showModal}
              onOk={() => (
                localStorage.setItem("open", false), setShowModal(false)
              )}
              onCancel={() => (
                localStorage.setItem("open", false), setShowModal(false)
              )}
              width={"45%"}
              footer={false}
              centered
            >
              <ul color="blue">
                <li>
                  <b>Invest with confidence, follow smart money. </b>
                </li>
                <li>
                  <b>Insiders know the business well. Follow them. </b>
                </li>
                <li>
                  <b>
                    Real time non-derivative filing data(from 2018) updated
                    within seconds.
                  </b>
                </li>
                <li>
                  <b>Smart filters to slice and dice data. </b>
                </li>
                <li>
                  <b>
                    Positive correlation between insider Purchases vs stock
                    price.
                  </b>
                </li>
                <li>
                  <b>
                    <a
                      href="https://www.investopedia.com/terms/f/form4.asp"
                      target="blank"
                    >
                      Form 4
                    </a>
                    Definition(from Investopedia)
                  </b>
                </li>
              </ul>
            </Modal> */}
            {tableLoading ? (
              <Row
                justify="center"
                align="middle"
                className="dashboardmaindiv width_100"
              >
                <Spin size="large" tip="Loading..." />
              </Row>
            ) : (
              <Row className="dashboardmaindiv width_100">
                {apiData ? (
                  apiData === "err" ? (
                    <Row justify="center">
                      <Col span={24}>something went wrong, comeback later</Col>
                    </Row>
                  ) : (
                    <>
                      <Col xl={3} md={6} xs={24} className="checkbox_Sidebar">
                        <Row className="dashboardSidebarTitle">
                          Report date /n (click)
                        </Row>
                        <Row className="mt_1 mb_1">
                          <Space
                            direction="vertical"
                            className="sidebarInput_main"
                          >
                            <Input
                              className="mb_1 sidebar_searchBox"
                              placeholder="input search text"
                              allowClear
                              suffix={<SearchOutlined />}
                              size="large"
                              onChange={(e) => {
                                setSearch(e.target.value);
                              }}
                              disabled={!authLoginChecker()}
                            />
                          </Space>
                          {tableLoading ? (
                            <Row
                              justify="center"
                              align="middle"
                              className="dashboardmaindiv width_100"
                            >
                              <Spin size="large" tip="Loading..." />
                            </Row>
                          ) : (
                            <>
                              <Checkbox.Group
                                onChange={handleChange}
                                className="industry_checkbox_grp"
                                value={checkedList}
                                options={
                                  listData &&
                                  listData
                                    .filter((val) => {
                                      if (search === "") {
                                        return val;
                                      } else if (
                                        val.title
                                          .toLowerCase()
                                          .includes(search.toLowerCase())
                                      ) {
                                        return val;
                                      }
                                    })
                                    .map((dd, key) => ({
                                      label: dd.title,
                                      value: dd.cd,
                                    }))
                                }
                              />
                            </>
                          )}
                        </Row>
                      </Col>
                      <Col
                        md={18}
                        xl={21}
                        xs={24}
                        className="deshboardTableSecondPart"
                      >
                        <Row>
                          <Row
                            justify="center"
                            className="scrollable-container deshboardTable__firstRow individual_investors_table_main"
                          >
                            <Col span={24}>
                              <Grid item xs={12}>
                                <div className="individual_investors_table_part">
                                  {tableLoading ? (
                                    <Row
                                      justify="center"
                                      align="middle"
                                      className="dashboardmaindiv width_100"
                                    >
                                      <Spin size="large" tip="Loading..." />
                                    </Row>
                                  ) : (
                                    <>
                                      <Filter
                                        setFilteredData={setFilteredData}
                                        filteredData={filteredData}
                                        isResetFilter={isResetFilter}
                                        setPageOption={setPageOption}
                                        filterLoading={filterLoading}
                                        filterData={setApiData}
                                        apiData={apiData}
                                        checkedList={checkedList}
                                        setTableLoading={setTableLoading}
                                        fetchSearchParams={
                                          fetchMainSearchParams
                                        }
                                        pageOption={pageOption}
                                        keyName="sic"
                                      />
                                      <Row className="dashboardSecondTablePart">
                                        <Row className="individual_investors_table_second_part">
                                          {pageOption.transaction_code ? (
                                            <span>
                                              {
                                                <Tag
                                                  className="close_tag"
                                                  style={
                                                    user_logged_in && {
                                                      opacity: 0.5,
                                                      cursor: "no-drop",
                                                    }
                                                  }
                                                  closable={!user_logged_in}
                                                  onClose={() =>
                                                    log(
                                                      "transaction_code",
                                                      pageOption.transaction_code
                                                    )
                                                  }
                                                >
                                                  {pageOption.transaction_code}{" "}
                                                </Tag>
                                              }
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.qty_min ||
                                          pageOption.qty_max ? (
                                            <Tag
                                              className="close_tag"
                                              onClose={() =>
                                                log(
                                                  "qty_min",
                                                  pageOption.qty_min
                                                )
                                              }
                                            >
                                              Quantity -{" "}
                                              {addCommas(
                                                pageOption.qty_min || 0
                                              )}
                                              ...
                                              {addCommas(pageOption.qty_max)}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.sic
                                            ? filteredIndustry.map((item) => (
                                                <Tag
                                                  key={item}
                                                  className="close_tag"
                                                  style={
                                                    user_logged_in && {
                                                      opacity: 0.5,
                                                      cursor: "no-drop",
                                                    }
                                                  }
                                                  closable={!user_logged_in}
                                                  onClose={() => (
                                                    log("sic", item.cd),
                                                    setSearch(" ")
                                                  )}
                                                >
                                                  {item.title}
                                                </Tag>
                                              ))
                                            : ""}
                                          {pageOption.traded_dt_min ||
                                          pageOption.traded_dt_max ? (
                                            <Tag
                                              className="close_tag"
                                              onClose={() =>
                                                log(
                                                  "traded_dt_min",
                                                  pageOption.traded_dt_min
                                                )
                                              }
                                            >
                                              Traded date -{" "}
                                              {moment(
                                                pageOption &&
                                                  pageOption.traded_dt_min
                                              ).format("MM-DD-YYYY")}
                                              ...
                                              {moment(
                                                pageOption &&
                                                  pageOption.traded_dt_max
                                              ).format("MM-DD-YYYY")}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.reported_dt_min ||
                                          pageOption.reported_dt_max ? (
                                            <Tag
                                              className="close_tag"
                                              onClose={() =>
                                                log(
                                                  "reported_dt_min",
                                                  pageOption.reported_dt_min
                                                )
                                              }
                                            >
                                              Reported date -{" "}
                                              {moment(
                                                pageOption &&
                                                  pageOption.reported_dt_min
                                                // ).format("YY.MM.DD")}
                                              ).format("YYYY-MM-DD")}
                                              ...
                                              {moment(
                                                pageOption &&
                                                  pageOption.reported_dt_max
                                              ).format("YYYY-MM-DD")}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.filing_dt_min ||
                                          pageOption.filing_dt_max ? (
                                            <Tag
                                              className="close_tag"
                                              onClose={() =>
                                                log(
                                                  "filing_dt_min",
                                                  pageOption.filing_dt_min
                                                )
                                              }
                                            >
                                              Filling date -{" "}
                                              {moment(
                                                pageOption &&
                                                  pageOption.filing_dt_min
                                              ).format("YYYY-MM-DD")}
                                              ...
                                              {moment(
                                                pageOption &&
                                                  pageOption.filing_dt_max
                                              ).format("YYYY-MM-DD")}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.price_min ||
                                          pageOption.price_max ? (
                                            <Tag
                                              className="close_tag"
                                              onClose={() =>
                                                log(
                                                  "price_min",
                                                  pageOption.price_min
                                                )
                                              }
                                            >
                                              {" "}
                                              Price -{" "}
                                              {addCommas(
                                                pageOption.price_min || 0
                                              )}
                                              ...
                                              {addCommas(pageOption.price_max)}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.total_min ||
                                          pageOption.total_max ? (
                                            <Tag
                                              className="close_tag"
                                              style={
                                                user_logged_in && {
                                                  opacity: 0.5,
                                                  cursor: "no-drop",
                                                }
                                              }
                                              closable={!user_logged_in}
                                              onClose={() =>
                                                log(
                                                  "total_min",
                                                  pageOption.total_min
                                                )
                                              }
                                            >
                                              Total -{" "}
                                              {addCommas(
                                                pageOption.total_min || 0
                                              )}
                                              ...
                                              {addCommas(pageOption.total_max)}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}

                                          {pageOption.officer ? (
                                            <span>
                                              {decodeURIComponent(
                                                pageOption.officer
                                              )
                                                .split(",")
                                                .map((ind) => {
                                                  // debugger
                                                  return (
                                                    <Tag
                                                      key={ind}
                                                      className="close_tag"
                                                      onClose={() =>
                                                        log("officer", ind)
                                                      }
                                                    >
                                                      {ind}{" "}
                                                    </Tag>
                                                  );
                                                })}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.issuer ? (
                                            <Tag
                                              className="close_tag"
                                              closable
                                              onClose={() => (
                                                log(
                                                  "issuer",
                                                  pageOption.issuer
                                                ),
                                                localStorage.removeItem(
                                                  "issuer"
                                                )
                                              )}
                                            >
                                              Issuer -{" "}
                                              {localStorage.getItem("issuer")}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.reporter ? (
                                            <Tag
                                              className="close_tag"
                                              closable
                                              onClose={() => (
                                                log(
                                                  "reporter",
                                                  pageOption.reporter
                                                ),
                                                localStorage.removeItem(
                                                  "reporter"
                                                )
                                              )}
                                            >
                                              Reporter -{" "}
                                              {localStorage.getItem("reporter")}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}
                                          {pageOption.ticker ? (
                                            <Tag
                                              className="close_tag"
                                              closable
                                              onClose={() =>
                                                log("ticker", pageOption.ticker)
                                              }
                                            >
                                              Ticker - {pageOption?.ticker}
                                            </Tag>
                                          ) : (
                                            ""
                                          )}

                                          {pageOption.issuer ? (
                                            apiData[0]?.issuer_cik ===
                                            pageOption.issuer ? (
                                              <Tag
                                                className="close_tag"
                                                closable
                                                onClose={() =>
                                                  log(
                                                    "issuer",
                                                    pageOption.issuer
                                                  )
                                                }
                                              >
                                                Issuer -{" "}
                                                {apiData[0]?.issuer_name}
                                              </Tag>
                                            ) : (
                                              ""
                                            )
                                          ) : (
                                            ""
                                          )}
                                        </Row>
                                        <Row className="custom_table_main">
                                          <CustomTable
                                            apiData={apiData}
                                            tableType="dashboard"
                                            totalRecord={totalRecord}
                                          />
                                        </Row>
                                      </Row>
                                    </>
                                  )}
                                </div>
                              </Grid>
                            </Col>
                          </Row>
                        </Row>
                      </Col>
                    </>
                  )
                ) : (
                  <Row justify="center" align="middle">
                    <Spin size="large" tip="Loading..."></Spin>
                  </Row>
                )}
              </Row>
            )}
          </>
        )}
      </Row>
      <Footer tableLoading={tableLoading} />
    </>
  );
};

export default Dashboard;
