import React, { useEffect, useState } from "react";
import { Row, Col, Card, Input, Form, Button } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import { openNotification } from "./Notification";
import Cookies from "js-cookie";
const baseUrl = process.env.REACT_APP_BASE_URL;
const ForgotPasswordWeb = (props) => {
  const {
    openLoginModal,
    setOpenLoginModal,
    forgetPasswordModal,
    setForgetPasswordModal,
  } = props;

  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  // To disable submit button at the beginning.
  useEffect(() => {
    forceUpdate({});
  }, []);
  const handleRegister = () => {
    // setRegisterModal(true);
    setOpenLoginModal(false);
  };
  const [loader, setLoader] = useState(false);
  // const [openLoginModal, setOpenLoginModal] = useState(false);
  const [signinError, setSigninError] = useState("");
  const history = useHistory();
  const onFinish = (values) => {
    fetch(`${baseUrl}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "http://premsite.com/",
      },
      body: JSON.stringify(values),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.status === "success") {
          setLoader(false);
          sessionStorage.setItem("islogged", true);
          // history.push("/dashboard");
          // console.log(data, "datattt");
          Cookies.set("userID", data && data.message && data.message.userid);
          history.push("/product-subscription");
          setOpenLoginModal(false);
        } else {
          setLoader(false);
          openNotification("Invalid Credentials", "error");
        }
      })
      .catch((err) => {
        openNotification("Something went wrong, comeback later", "error");
        setLoader(false);
      })
      .finally(() => {
        // setOpenLoginModal(false);
      });
  };
  return (
    <Row
      align="middle"
      justify="center"
      style={{
        backgroundColor: "#F5F5F5",
        //  height: "90vh"
      }}
    >
      <Col span={24}>
        <Card style={{ boxShadow: "5px 7px 20px -1px gainsboro" }}>
          <Row>
            <Col span={24}>
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Row>
                  <h1 style={{ fontWeight: "bold" }}>Forget Password</h1>
                </Row>
                <Row>
                  <Form.Item
                    style={{ width: "100%" }}
                    name="userid"
                    rules={[
                      {
                        required: true,
                        message: "Please input your userid!",
                      },
                    ]}
                    label={<span className="t_gray">USER ID</span>}
                  >
                    <Input placeholder="Your userid" />
                  </Form.Item>
                </Row>

                <Form.Item
                  name="password"
                  label={<span className="t_gray">Password</span>}
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  label={<span className="t_gray">Confirm Password</span>}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <small className="signinError">{signinError}</small>
                <Row>
                  <Form.Item style={{ width: "100%" }} shouldUpdate>
                    {/* <Button type="primary" htmlType="submit"> */}

                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          height: "50px",
                        }}
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                      >
                        LOGIN NOW
                      </Button>
                    )}
                  </Form.Item>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ForgotPasswordWeb;
