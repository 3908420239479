import { Col,  Row, Typography } from "antd";
import React from "react";
import InversorMen from "../assets/HomeInvestor.png";

const HomePageInvestor = () => {
  return (
    <Row className="inversorMainImage" >
      <Col lg={{ span: 9, offset: 4 }}  xs={{ span: 20, offset: 2 }} className="text-center investorsMainTextPart" >
        <span className="InvestorMainTitle">
          How can investors use Form 4 filings to their advantage?
        </span>
        <Typography className="inversorSubTitle">
          Form 4 filings can help investors identify transactions that top
          corporate insiders such as CEOs, CFOs, and Chairmen have made in US
          publicly-listed companies. This is valuable because corporate insiders
          have a genuine information advantage over other investors and a number
          of academic studies have found a link between insider transaction
          activity and future stock returns. As such, Form 4 filings can
          potentially be used to generate investment ideas.
        </Typography>
        <Typography className="inversorSubTitle">
          As a general rule, Form 4 filings that show substantial insider buying
          activity are a bullish signal. If insiders are buying, it shows that
          they are confident about the future and expect the company's share
          price to rise.
        </Typography>
        <Typography className="inversorSubTitle">
          Form 4 filings that show that insiders have been selling stock are a
          little more difficult to interpret. This is because there are a number
          of reasons that insiders sell stock that have nothing to do with the
          company's future prospects. For example, an insider may simply wish to
          diversify their investments. Form 4 filings that show large insider
          sales should not be ignored, however, as in some situations, it can be
          an indication that insiders are offloading their stock in the
          expectation that the stock will soon fall.
        </Typography>
      </Col>
      <Col  lg={{span:6, offset:1}} xs={{ span: 14, offset: 5 }} >
        <img
          alt="investorImage"
          src={InversorMen}
          width="100%"
         className="investorImage" 
          style={{}}
        />
      </Col>
    </Row>
  );
};

export default HomePageInvestor;


