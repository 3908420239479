import { Col, Row, Typography } from "antd";
import React from "react";
import { useHistory, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import "./Styles/Footer.scss";

const Footer = ({ tableLoading }) => {
  const history = useHistory();
  const location = useLocation();

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Row
        style={{
          backgroundColor:
            location.pathname.slice("/").includes("filter") && "#F9F7F8",
        }}
        className="footerparentMain"
      >
        <Row
          className="footer"
          // style={{ position: tableLoading ? "fixed" : "" }}
        >
          <Col xs={24} align="center" className="footerLogoMainCol">
            <Typography className="footerLogo">100ktrading.info</Typography>
          </Col>
          <Col xs={24} align="center">
            <hr className="hr1" />
            <Col className="footerTitleName footerNavContainer">
              <NavLink
                className="inactive"
                activeClassName="active"
                exact={true}
                to="/filter?total_min=100000&transaction_code=Purchases"
              >
                Home
              </NavLink>
              <NavLink
                className="inactive"
                activeClassName="active"
                exact={true}
                to="/psilon/contact"
              >
                Contact Us
              </NavLink>
              {/* <NavLink
                className="inactive"
                activeClassName="active"
                exact={true}
                to="/psilon/about"
              >
                About Us
              </NavLink> */}
              <NavLink
                className="inactive"
                activeClassName="active"
                exact={true}
                to="/disclaimer"
              >
                Disclaimer
              </NavLink>
              <NavLink
                className="inactive"
                activeClassName="active"
                exact={true}
                to="/privacypolicy"
              >
                Privacy Policy
              </NavLink>
            </Col>
            <hr className="hr1" />
          </Col>
          <Col xs={24} align="center" className="footerTitlecol">
            <Typography className="footerTitle">
              {`© 2005- ${currentYear} Your company All rights reserved`}
            </Typography>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Footer;

// <Col span={22}>
// <Row>
//   <Col
//     style={{ marginRight: "20px" }}
//     onClick={() => history.push("/ ")}
//   >
//     <NavLink
//       className="inactive"
//       activeClassName="active"
//       exact={true}
//       to="/ "
//     >
//       Home
//     </NavLink>
//   </Col>

//   <Col
//     style={{ marginRight: "20px" }}
//     onClick={() => history.push("/psilon/contact")}
//   >
//     <NavLink
//       className="inactive"
//       activeClassName="active"
//       exact={true}
//       to="/psilon/contact"
//     >
//       {" "}
//       Contact Us
//     </NavLink>
//   </Col>
//   <Col
//     style={{ marginRight: "20px" }}
//     onClick={() => history.push("/about-us")}
//   >
//     <NavLink
//       className="inactive"
//       activeClassName="active"
//       exact={true}
//       to="/about-us"
//     >
//       {" "}
//       About Us
//     </NavLink>
//   </Col>

//   <Col
//     style={{ marginRight: "20px" }}
//     onClick={() => history.push("/disclaimer")}
//   >
//     <NavLink
//       className="inactive"
//       activeClassName="active"
//       exact={true}
//       to="/disclaimer"
//     >
//       {" "}
//       Disclaimer
//     </NavLink>
//   </Col>
//   <Col
//     style={{ marginRight: "20px" }}
//     onClick={() => history.push("/privacypolicy")}
//   >
//     <NavLink
//       className="inactive"
//       activeClassName="active"
//       exact={true}
//       to="/privacypolicy"
//     >
//       Privacy Policy
//     </NavLink>
//   </Col>
//   <Col span={20} style={{ marginTop: "5em" }}>
//     © 2005-2021 Your company All rights reserved
//   </Col>
// </Row>
// </Col>
