import { Card, Col, Row, Input, Button, notification } from "antd";
import React, { useState } from "react";
import Footer from "./Footer";
import "./Styles/Footer.scss";
const Context = React.createContext({ name: "Default" });
const baseUrl = process.env.REACT_APP_BASE_URL;
const { TextArea } = Input;
const Feedback = () => {
  const [state, setState] = useState({ email: "", feedback: "" });
  const [isLoading, setLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, description) => {
    api[type]({
      message: type.charAt(0).toUpperCase() + type.slice(1),
      description,
      placement: "topRight",
    });
  };
  function submitFeedback() {
    console.log("sib,ot call");
    if (validateForm(state.email)) {
      setLoading(true);
      fetch(`${baseUrl}feedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://premsite.com/",
        },
        body: JSON.stringify(state),
      })
        .then((resp) => resp.json())
        .then((data) => {
          if (data && data.code === 200) {
            openNotification(
              "success",
              "Feedback has been added successfully!"
            );
            setLoading(false);
            setState({ email: "", feedback: "" });
          }
        })
        .catch((err) => {
          setLoading(false);
          openNotification("error", "Something went wrong!");
          console.log("err: ", err);
        });
    }
  }

  function validateForm(value) {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(validRegex)) {
      return true;
    } else {
      openNotification("error", "Invalid email address!");
      return false;
    }
  }

  return (
    <Context.Provider>
      {contextHolder}
      {/* <Row justify="center" style={{ height: "10vh", paddingTop: "3em" }}>
        <Col span={7} style={{}}>
          <h3>How was your experience?</h3>
          <Rate
            defaultValue={3}
            character={({ index }) => customIcons[index + 1]}
          />
        </Col>
        <Divider type="vertical" style={{ height: "8vh", margin: "0px",  }} />
        <Col span={8} style={{marginLeft:"2em"}}>
          <h3>Help Improve this page</h3>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            style={{ marginRight: "10px", width: "100px" }}
          >
            Yes
          </Button>
          <Button style={{ width: "100px" }} icon={<CloseOutlined />} disabled>
            No
          </Button>
        </Col>
      </Row> */}
      <Row justify="center" style={{ paddingTop: "3em" }}>
        <Col span={16}>
          {/* <Row >
            <Col  span={10}>
              <h3>How was your experience?</h3>
              <Rate
                defaultValue={3}
                character={({ index }) => customIcons[index + 1]}
              />
            </Col>
            <Divider type="vertical" style={{ height: "11vh", margin: "0px" }} />
            <Col span={12}
              style={{ marginLeft: "2em" }}
            >
              <h3>Help Improve this page</h3>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                style={{ marginRight: "10px", width: "100px" }}
              >
                Yes
              </Button>
              <Button
                style={{ width: "100px" }}
                icon={<CloseOutlined />}
                disabled
              >
                No
              </Button>
            </Col>
          </Row> */}
        </Col>
      </Row>
      <Row justify="center" style={{ height: "70vh", paddingTop: "1em" }}>
        <Col span={16}>
          <Card bodyStyle={{ backgroundColor: "whitesmoke" }}>
            <Row justify="space-between">
              <Col>
                <h3>Great. Would you like to add anything else?</h3>
              </Col>
              {/* <Col className="textClr">What's issue?</Col> */}
            </Row>
            <Row style={{ marginBottom: "10px" }}>
              <Input
                value={state.email}
                onChange={(e) => setState({ ...state, email: e.target.value })}
                placeholder="Email address"
              />
            </Row>
            <Row>
              <TextArea
                value={state.feedback}
                onChange={(e) =>
                  setState({ ...state, feedback: e.target.value })
                }
                rows={4}
                placeholder="How can we help you?"
              />
            </Row>
            <br></br>
            {/* <Row>
              <p>
                Please post <span className="textClr">helpful feedback</span>.By
                posting, you agree to transparency uner{" "}
                <span className="textClr"
                 onClick={() => history.push("/terms")}
                >
                Terms
                </span>
              </p>
            </Row> */}
            <Row>
              <Button
                disabled={!state.email || !state.feedback}
                loading={isLoading}
                onClick={submitFeedback}
                type="primary"
              >
                Post Your Feedback
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row style={{ position: "fixed", width: "100%", bottom: "0" }}>
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </Context.Provider>
  );
};

export default Feedback;
