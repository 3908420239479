import Cookies from "js-cookie";
import React, { createContext, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ContactUs from "./ContactUs";
import Dashboard from "./Dashboard";
import Disclaimer from "./Disclaimer";
import Feedback from "./Feedback";
import Header from "./Header";
import Home from "./Home";
import Individual from "./Individual";
import Investors from "./Investors";
import Login from "./Login";
import PrivacyPolicy from "./PrivacyPolicy";
import PrivateRoute from "./PrivateRoute";
import ProductSubscription from "./ProductSubscription";
import Register from "./Register";
import Stripe from "./Stripe";
import Terms from "./Terms";
import UserSetting from "./UserSetting";

// export const PageContext = createContext();

if (Cookies.get("user_logged_in") === undefined) {
  Cookies.set("user_logged_in", "N");
}
const Routing = () => {
  // const [page, setPage] = useState(1);
  console.log(process.env.REACT_APP_BASE_URL, "process.env.REACT_APP_ENV");
  return (
    <BrowserRouter>
      <Switch>
        <Header>
          {/* <Route exact path="/" component={LoginRegister} /> */}
          {/* <Redirect
            exact
            from="/"
            to="/filter?page=1&size=100&total_min=100000&transaction_code=Purchases"
          /> */}
          {/* <PageContext.Provider value={{ page, setPage }}> */}
          <Route exact path="/" component={Home} />
          <Route exact path="/filter" component={Dashboard} />
          <Route
            exact
            path={["/investors/page_type=investors/filter"]}
            component={Investors}
          />
          <Route
            exact
            path={["/individual/page_type=individual/filter"]}
            component={Individual}
          />

          {/* <PrivateRoute exact path="/filter" component={Dashboard} />
          <PrivateRoute exact path="/investors/page_type=investors/filter" component={Investors} />
          <PrivateRoute exact path="/individual/page_type=individual/filter" component={Individual} /> */}

          <Route exact path="/login" component={Login} />
          <Route exact path="/googleLoginSuccess" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/disclaimer" component={Disclaimer} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/Terms" component={Terms} />
          <Route exact path="/setting" component={UserSetting} />
          {/* <Route exact path="/:slug" component={Dashboard} /> */}
          <Route exact path="/stripe" component={Stripe} />
          <Route
            exact
            path="/product-subscription"
            component={ProductSubscription}
          />
          <Route exact path="/feedback" component={Feedback} />
          <Route exact path="/psilon/contact" component={ContactUs} />
          <Route exact path="/psilon/about" component={ContactUs} />
          {/* </PageContext.Provider> */}
        </Header>
      </Switch>
    </BrowserRouter>
  );
};

export default Routing;
