import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import '../css/stripe.css'
// import { STRIPE_PUBLISH_KEY, MONTHLY_PRICE_ID, YEARLY_PRICE_ID, LIFETIME_PRICE_ID } from '../../common/constants'
// import { getFromLocalStorage } from '../../common/common'
const stripePromise = loadStripe(
  "pk_test_51HgxLCDmFPn9twUcQOl4Mg4GzWeTjTQemvu4yjFYRgFql2adYTlbQYDONvZggOp7gQquJ5rTPuR7FBLLv0l80XnL00RlxVxoFZ"
);

const CheckoutForm = ({ vendorDetails }) => {
  useEffect(() => {
    const handleClick = async (event) => {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: [
          {
            price: "price_1IrRnOKNWNPlRrHRNyJUlBTI", // in live set priceId here
            quantity: 1,
            // customer_email:userData.email
          },
        ],
        mode: "subscription",
        // customer:"mit",
        // customerEmail:userData.email,
        successUrl: "http://localhost:3000/setting",
        cancelUrl: "http://localhost:3000/sign-in",
      });
      console.log(error);
    };
    handleClick();
  }, []);
  return <></>;
};
const Wrapper = (props) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
      <img
        alt="loading"
        src="https://i.pinimg.com/originals/24/2e/12/242e12c5180073807fc7ff2d5f244d1c.gif"
      />
    </Elements>
  );
};

export default Wrapper;
