import React from "react";
import { Row, Checkbox, Input, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./Styles/Dashboard.scss";
import Cookies from "js-cookie";
import { authLoginChecker } from "../helper/commonFunction";

const Sidebar = ({
  tableLoading,
  setSearch,
  handleChange,
  listData,
  search,
  checkedList,
}) => {
  const { Search } = Input;
  const user_logged_in = !authLoginChecker();
  return (
    <Row className="mt_1 mb_1">
      <Space direction="vertical" className="sidebarInput_main">
        <Input
          className="mb_1 sidebar_searchBox"
          placeholder="input search text"
          allowClear
          disabled={user_logged_in}
          suffix={<SearchOutlined />}
          size="large"
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
      </Space>

      {tableLoading ? (
        <Row
          justify="center"
          align="middle"
          className="dashboardmaindiv individual_investors_table_main"
        >
          <Spin
            size="large"
            tip="Loading..."
            // spinning={isLoading}
          />
        </Row>
      ) : (
        <>
          <Checkbox.Group
            className="sidebar_checkbox industry_checkbox_grp"
            onChange={handleChange}
            value={checkedList}
            options={
              listData &&
              listData
                .filter((val) => {
                  if (search == "") {
                    return val;
                  } else if (
                    val.name.toLowerCase().includes(search.toLowerCase())
                  ) {
                    return val;
                  }
                })
                .map((dd, key) => ({
                  label: dd.name,
                  value: dd.cik,
                }))
            }
          />
        </>
      )}
    </Row>
  );
};

export default Sidebar;
