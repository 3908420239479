import Cookies from "js-cookie";
export const getUrlVars = () => {
  // debugger
  var vars = [],
    hash;
  var hashes = window.location.href
    .slice(window.location.href.indexOf("?") + 1)
    .split("&");
  console.log(hashes, "hashes");
  for (var i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    vars[hash[0]] = hash[1];
  }
  console.log(vars, "abcd");
  return vars.length === 1 ? [] : vars;
};

export const updateSearchParams2 = (searchObj, history, url) => {
  const allVars = getUrlVars();
  let searchString = "";
  if (allVars) {
    for (var k in allVars) {
      if (allVars.hasOwnProperty(k)) {
        if (searchObj[k] || allVars[k]) {
          let value = searchObj[k];
          if (value && value.length !== 0) {
            searchString += `${searchString === "" ? "?" : "&"}${k}=${value}`;
          }
        }
      }
    }
  }

  for (const key in searchObj) {
    if (Object.hasOwnProperty.call(searchObj, key)) {
      const element = searchObj[key];
      if (!allVars[key] && element && element.length !== 0) {
        searchString += `${searchString === "" ? "?" : "&"}${key}=${element}`;
      }
    }
  }
  history.replace(`${url}/filter${searchString}`);
  // fetchData();
};

export const updateSearchParams1 = (searchObj, type, type1, history, url) => {
  const allVars = getUrlVars();

  let searchString = "";
  if (allVars) {
    for (var k in allVars) {
      if (allVars.hasOwnProperty(k)) {
        if (searchObj[k] || allVars[k]) {
          let value = searchObj[k] || allVars[k];
          if (value && value.length !== 0) {
            if (type == k) {
              searchString += `${searchString === "" ? "?" : "&"}`;
            } else if (type1 == k) {
              searchString += `${searchString === "" ? "?" : "&"}`;
            } else {
              searchString += `${searchString === "" ? "?" : "&"}${k}=${value}`;
            }
          }
        }
      }
    }
  }

  for (const key in searchObj) {
    if (Object.hasOwnProperty.call(searchObj, key)) {
      const element = searchObj[key];
      if (!allVars[key] && element && element.length !== 0) {
        searchString += `${searchString === "" ? "?" : "&"}${key}=${element}`;
      }
    }
  }
  history.replace(`${url}/filter${searchString}`);
};
export const updateSearchParams = (searchObj, history, url) => {
  const allVars = getUrlVars();
  let searchString = "";
  if (allVars) {
    for (var k in allVars) {
      if (allVars.hasOwnProperty(k)) {
        if (searchObj[k] || allVars[k]) {
          let value = searchObj[k] || allVars[k];
          if (value && value.length !== 0) {
            searchString += `${searchString === "" ? "?" : "&"}${k}=${value}`;
          }
        }
      }
    }
  }

  for (const key in searchObj) {
    if (Object.hasOwnProperty.call(searchObj, key)) {
      const element = searchObj[key];
      if (!allVars[key] && element && element.length !== 0) {
        searchString += `${searchString === "" ? "?" : "&"}${key}=${element}`;
      }
    }
  }
  history.replace(`${url}/filter${searchString}`);
  // fetchData();
};
export const fetchSearchParams = (pageOption) => {
  console.log(pageOption, "pageOptionpageOption");
  let searchOptions = {
    size: pageOption.size,
    page: pageOption.page,
  };

  if (window && window.location.href) {
    const url = new URL(window.location.href);
    searchOptions.size = url.searchParams.get("size") || pageOption.size;
    searchOptions.page = url.searchParams.get("page") || pageOption.page;
    if (url.searchParams.get("reporter")) {
      searchOptions.reporter =
        url.searchParams.get("reporter") || pageOption.reporter;
    }
    if (url.searchParams.get("reporter")) {
      searchOptions.reporter = url.searchParams.get("reporter");
    }
    if (url.searchParams.get("ticker")) {
      searchOptions.ticker = url.searchParams.get("ticker");
    }
    if (url.searchParams.get("issuer")) {
      searchOptions.issuer = url.searchParams.get("issuer");
    }
    if (url.searchParams.get("officer")) {
      searchOptions.officer = url.searchParams.get("officer");
    }
    if (url.searchParams.get("transaction_code")) {
      searchOptions.transaction_code = url.searchParams.get("transaction_code");
    }
    if (url.searchParams.get("filing_dt_max")) {
      searchOptions.filing_dt_max = url.searchParams.get("filing_dt_max");
    }
    if (url.searchParams.get("filing_dt_min")) {
      searchOptions.filing_dt_min = url.searchParams.get("filing_dt_min");
    }
    if (url.searchParams.get("price_max")) {
      searchOptions.price_max = url.searchParams.get("price_max");
    }
    if (url.searchParams.get("price_min")) {
      searchOptions.price_min = url.searchParams.get("price_min");
    }
    if (url.searchParams.get("qty_max")) {
      searchOptions.qty_max = url.searchParams.get("qty_max");
    }
    if (url.searchParams.get("qty_min")) {
      searchOptions.qty_min = url.searchParams.get("qty_min");
    }
    if (url.searchParams.get("reported_dt_max")) {
      searchOptions.reported_dt_max = url.searchParams.get("reported_dt_max");
    }
    if (url.searchParams.get("reported_dt_min")) {
      searchOptions.reported_dt_min = url.searchParams.get("reported_dt_min");
    }
    if (url.searchParams.get("total_max")) {
      searchOptions.total_max = url.searchParams.get("total_max");
    }
    if (url.searchParams.get("total_min")) {
      searchOptions.total_min = url.searchParams.get("total_min");
    }
    if (url.searchParams.get("traded_dt_max")) {
      searchOptions.traded_dt_max = url.searchParams.get("traded_dt_max");
    }
    if (url.searchParams.get("traded_dt_min")) {
      searchOptions.traded_dt_min = url.searchParams.get("traded_dt_min");
    }
  }

  return {
    ...searchOptions,
  };
};
export const fetchMainSearchParams = (pageOption) => {
  let searchOptions = {
    // size: pageOption.size,
    // page: pageOption.page,
  };

  if (window && window.location.href) {
    const url = new URL(window.location.href);
    // searchOptions.size = url.searchParams.get("size") || pageOption.size;
    // console.log(pageOption.size, "pageOption.size");
    // searchOptions.page = url.searchParams.get("page") || pageOption.page;
    if (url.searchParams.get("sic")) {
      searchOptions.sic = url.searchParams.get("sic") || pageOption.sic;
    }
    if (url.searchParams.get("reporter")) {
      searchOptions.reporter = url.searchParams.get("reporter");
    }
    if (url.searchParams.get("ticker")) {
      searchOptions.ticker = url.searchParams.get("ticker");
    }
    if (url.searchParams.get("issuer")) {
      searchOptions.issuer = url.searchParams.get("issuer");
    }
    if (url.searchParams.get("officer")) {
      searchOptions.officer = url.searchParams.get("officer");
    }
    if (url.searchParams.get("transaction_code")) {
      searchOptions.transaction_code = url.searchParams.get("transaction_code");
    }
    if (url.searchParams.get("filing_dt_max")) {
      searchOptions.filing_dt_max = url.searchParams.get("filing_dt_max");
    }
    if (url.searchParams.get("filing_dt_min")) {
      searchOptions.filing_dt_min = url.searchParams.get("filing_dt_min");
    }
    if (url.searchParams.get("price_max")) {
      searchOptions.price_max = url.searchParams.get("price_max");
    }
    if (url.searchParams.get("price_min")) {
      searchOptions.price_min = url.searchParams.get("price_min");
    }
    if (url.searchParams.get("qty_max")) {
      searchOptions.qty_max = url.searchParams.get("qty_max");
    }
    if (url.searchParams.get("qty_min")) {
      searchOptions.qty_min = url.searchParams.get("qty_min");
    }
    if (url.searchParams.get("reported_dt_max")) {
      searchOptions.reported_dt_max = url.searchParams.get("reported_dt_max");
    }
    if (url.searchParams.get("reported_dt_min")) {
      searchOptions.reported_dt_min = url.searchParams.get("reported_dt_min");
    }
    if (url.searchParams.get("total_max")) {
      searchOptions.total_max = url.searchParams.get("total_max");
    }
    if (url.searchParams.get("total_min")) {
      searchOptions.total_min = url.searchParams.get("total_min");
    }
    if (url.searchParams.get("traded_dt_max")) {
      searchOptions.traded_dt_max = url.searchParams.get("traded_dt_max");
    }
    if (url.searchParams.get("traded_dt_min")) {
      searchOptions.traded_dt_min = url.searchParams.get("traded_dt_min");
    }
  }
  console.log(searchOptions, "searchOpriond");
  return {
    ...searchOptions,
  };
};
export const checkIsSame = (array1, array2) => {
  return (
    array1?.length == array2?.length &&
    array1?.every(function (element, index) {
      return element == array2[index];
    })
  );
};
export const addCommas = (num) =>
  num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
export const removeCommas = (num) => num?.replace(/\,/g, "");
export const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

export const authLoginChecker = () => {
  if (process.env.REACT_APP_IS_AUTHENTICATE_BYPASS === "true") {
    return true;
  } else if (
    process.env.REACT_APP_IS_AUTHENTICATE_BYPASS === "false" &&
    Cookies?.get("user_logged_in") === "Y"
  ) {
    return true;
  } else {
    return false;
  }
};
