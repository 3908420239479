import React from "react";
import ReactDOM from "react-dom";
import App from "./Components/Routing";
import "./index.css";
import "antd/dist/antd.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import "antd/dist/reset.css";
import ReactGA from "react-ga4";
// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO);
// ReactGA.pageview(window.location.pathname + window.location.search);

// ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO);
// ReactGA.send("pageview");
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
ReactDOM.render(
  <GoogleOAuthProvider clientId={googleClientId}>
    <App />
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
