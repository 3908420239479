import { Col, Menu, Modal, Row, Typography } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation, withRouter } from "react-router-dom";
import ForgotPasswordWeb from "./ForgotPasswordWeb";
import Login from "./Login";
import Register from "./Register";
import "./Styles/Header.scss";

const { SubMenu } = Menu;

const Header = (props) => {
  const history = useHistory();
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation();

  const handleCancel = () => {
    setOpenLoginModal(false);
  };
  const handleCancel1 = () => {
    setRegisterModal(false);
  };
  const handleManu = (e) => {
    e.preventDefault();
    setShowMenu(true);
  };

  const [width, setWidth] = useState(window.innerWidth);
  React.useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  const breakpoint = 600;
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const [current, setCurrent] = useState(
    location.pathname === "/" || location.pathname === ""
      ? "/dashboard"
      : location.pathname
  );
  //or simply use const [current, setCurrent] = useState(location.pathname)
  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname);
      }
    }
  }, [location, current]);
  const menuItems = [
    { key: "1", route: "/" },
    { key: "4", route: "/psilon/contact" },
    { key: "8", route: "/login" },
    {
      key: "21",
      route: "/filter?total_min=5000000&transaction_code=Purchases",
    },
    { key: "31", route: "/filter?total_min=5000000&transaction_code=Sales" },
    {
      key: "41",
      route: "/filter?total_min=5000000&transaction_code=Purchases&sic=2834",
    },
    {
      key: "51",
      route: "/filter?total_min=5000000&transaction_code=Sales&sic=2834",
    },
    {
      key: "61",
      route:
        "/investors/page_type=investors/filter?total_min=100000&transaction_code=Purchases",
    },
    {
      key: "71",
      route:
        "/individual/page_type=individual/filter?total_min=100000&transaction_code=Purchases",
    },
  ];

  function logout() {
    try {
      const access_token = Cookies.get("google_login");
      console.log(access_token, "access_token");
      fetch("https://oauth2.googleapis.com/revoke?token=" + access_token, {
        method: "POST",
        header: {
          "Content-type": "application/x-www-form-urlencoded",
        },
      }).then((res) => {
        Cookies.remove("google_login");
      });
    } catch (error) {
      console.log(error);
    } finally {
      sessionStorage.clear();
      Cookies.set("user_logged_in", "N");
      history.push("/Login");
    }
  }

  return (
    <>
      <Row className="headerAllMain">
        <Row
          className="headerdiv"
          align="middle"
          justify="end"
          style={{
            position: location.pathname === "/" ? "absolute" : "static",
          }}
        >
          <Col lg={2} xs={0}></Col>
          <Col lg={5} sm={10} xs={14}>
            <Typography
              className="headermainLogoText"
              onClick={() => history.push("/")}
              style={{
                color: location.pathname === "/" ? "white" : "black",
              }}
            >
              100ktrading.info
            </Typography>
          </Col>
          <Col sm={14} lg={15} xs={10} className="header-menu">
            <Menu
              mode="horizontal"
              defaultSelectedKeys={["/"]}
              style={{
                color: location.pathname === "/" ? "white" : "black",
                backgroundColor: "transparent",
              }}
              selectedKeys={[
                menuItems?.find(
                  (cur) => cur?.route === location?.pathname + location?.search
                )?.key,
              ]}
            >
              <Menu.Item
                key="1"
                onClick={() => history.push("/")}
                className="headerMenuItems"
              >
                Home
              </Menu.Item>
              <Menu.SubMenu
                key="SubMenu"
                title="Reports"
                className="headerMenuItems"
              >
                <Menu.Item
                  key="21"
                  onClick={() =>
                    history.push(
                      "/filter?total_min=5000000&transaction_code=Purchases"
                    )
                  }
                  className="headerMenuItems"
                >
                  Greater than 5 million - Purchases
                </Menu.Item>
                <Menu.Item
                  key="31"
                  onClick={() =>
                    history.push(
                      "/filter?total_min=5000000&transaction_code=Sales"
                    )
                  }
                  className="headerMenuItems"
                >
                  Greater than 5 million - sales
                </Menu.Item>
                <Menu.Item
                  key="41"
                  onClick={() =>
                    history.push(
                      "/filter?total_min=5000000&transaction_code=Purchases&sic=2834"
                    )
                  }
                  className="headerMenuItems"
                >
                  Pharma - Greater than 5 million - Purchases
                </Menu.Item>
                <Menu.Item
                  key="51"
                  onClick={() =>
                    history.push(
                      "/filter?total_min=5000000&transaction_code=Sales&sic=2834"
                    )
                  }
                  className="headerMenuItems"
                >
                  Pharma - Greater than 5 million - sales
                </Menu.Item>
                <Menu.Item
                  key="61"
                  onClick={() =>
                    history.push(
                      "/investors/page_type=investors/filter?total_min=100000&transaction_code=Purchases"
                    )
                  }
                  className="headerMenuItems"
                >
                  Top Investors
                </Menu.Item>
                <Menu.Item
                  key="71"
                  onClick={() =>
                    history.push(
                      `/individual/page_type=individual/filter?total_min=100000&transaction_code=Purchases`
                    )
                  }
                  className="headerMenuItems"
                >
                  Top Individuals
                </Menu.Item>
              </Menu.SubMenu>
              <Menu.Item
                key="4"
                onClick={() => history.push("/psilon/contact")}
                className="headerMenuItems"
              >
                Contact Us
              </Menu.Item>
              {/* <Menu.Item
                key="5"
                onClick={() => history.push("/psilon/about")}
                className="headerMenuItems"
              >
                About Us
              </Menu.Item>
              <Menu.Item
                key="6"
                onClick={() => history.push("/disclaimer")}
                className="headerMenuItems"
              >
                Disclaimer
              </Menu.Item>

              <Menu.Item
                key="7"
                onClick={() => history.push("/setting")}
                className="headerMenuItems"
              >
                Setting
              </Menu.Item>*/}
              {Cookies.get("user_logged_in") !== "Y" ? (
                <Menu.Item
                  key="8"
                  onClick={() => history.push("/login")}
                  className="headerMenuItems"
                  selectedKeys={[location.pathname === "/Login" ? "8" : "8"]}
                >
                  Login
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="6"
                  className="headerMenuItems"
                  onClick={() => {
                    logout();
                  }}
                >
                  Log out
                </Menu.Item>
              )}
              {/* {!!!sessionStorage.getItem("islogged") ? (
                <Menu.Item
                  key="8"
                  onClick={() => history.push("/Login")}
                  className="headerMenuItems"
                >
                  Login
                </Menu.Item>
              ) : (
                <Menu.Item
                  key="6"
                  onClick={() => {
                    sessionStorage.clear();
                    Cookies.remove("userID");
                    history.push("/Login");
                  }}
                >
                  Log out
                </Menu.Item>
              )} */}
            </Menu>
          </Col>
          <Col lg={2} xs={0}></Col>

          <Col
            // span={4}
            xs={12}
            md={4}
            lg={3}
            xl={2}
            xxl={2}
            sm={4}
          >
            <Row align="middle" justify="end"></Row>

            <Modal
              className="login_modal"
              // title="Basic Modal"
              visible={openLoginModal}
              Header={false}
              footer={false}
              // onOk={handleOk}
              onCancel={handleCancel}
            >
              <Login
                setOpenLoginModal={setOpenLoginModal}
                openLoginModal={openLoginModal}
                setRegisterModal={setRegisterModal}
                setForgetPasswordModal={setForgetPasswordModal}
              />
            </Modal>
            <Modal
              className="login_modal"
              // title="Basic Modal"
              visible={registerModal}
              Header={false}
              footer={false}
              // onOk={handleOk}
              onCancel={handleCancel1}
            >
              <Register
                setRegisterModal={setRegisterModal}
                registerModal={registerModal}
                setOpenLoginModal={setOpenLoginModal}
              />
            </Modal>
            <Modal
              className="login_modal"
              // title="Basic Modal"
              visible={forgetPasswordModal}
              Header={false}
              footer={false}
              // onOk={handleOk}
              onCancel={handleCancel}
            >
              <ForgotPasswordWeb
                openLoginModal={openLoginModal}
                setOpenLoginModal={setOpenLoginModal}
                forgetPasswordModal={forgetPasswordModal}
              />
            </Modal>
          </Col>
          <Row style={{ width: "100%" }}>
            <Col lg={2} xs={0}></Col>
            <Col lg={20} xs={24} className="headerHrPadding">
              <hr className="headerHrmain" />
            </Col>
          </Row>
        </Row>
      </Row>

      <div className="allParts">{props.children}</div>
    </>
  );
};

export default withRouter(Header);
